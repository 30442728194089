import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import firstBy from 'thenby'
import moment from 'moment'
import numeral from 'numeral'
import ReactTooltip from 'react-tooltip'
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap/lib'

// Components
import Loading from '../components/Loading'
import SetQuinielaHeaders from '../components/SetQuinielaHeaders'
import ClockHandler from '../components/Clock/ClockHandler'
import { ISRTooltip } from '../components/TablaPosiciones/TablaPosiciones'

// Utils
import utils from './utils';
import API from '../utils/api'
import Utils from './quinielasUtils'
import branchUtils from './branchUtils'

// Assets
import billete from '../assets/images/cash1-50.png'
import maxIcon from '../assets/images/max-icon.png'
import regalo from '../assets/images/gift-50.png'
import pickcoin from '../assets/images/pickcoin1-50.png'
import pickcoinPlata from '../assets/images/pickcoin1-50-plata.png'
import freeRollIcon from '../assets/images/currency-gift.png'
import ticketIcon from '../assets/images/silver_ticket_pick_empty.png'
import garantizada from '../assets/images/garantizadaBlancoIcon-50.png'
import bracket from '../assets/images/bracketIcon.png'
import bracketIcon from '../assets/images/type_game/bracket-type.png'
import line from '../assets/images/lineaBlancoIcon-50.png'
import natural from '../assets/images/naturalBlancoIcon-50.png'
import Fútbol from '../assets/images/FutbolBall-50.png'
import Baseball from '../assets/images/BaseballBall-50.png'
import Basketball from '../assets/images/BasketballBall-50.png'
import Tennis from '../assets/images/TennisBall-50.png'
import Americano from '../assets/images/AmericanoBall-50.png'
import Golf from '../assets/images/GolfBall-50.png'
import Volante from '../assets/images/Volante-50.png'
import Hockey from '../assets/images/Hockey.png'
import F1 from '../assets/images/Volante-50.png'
import pickcoins from '../assets/images/pickcoin1-50.png'
import pickcoinsPlata from '../assets/images/pickcoin1-50-plata.png'
import iconQuiniela from '../assets/images/icon-quiniela.png'
import iconSurvivor from '../assets/images/icon-survivor.png'
import iconCl from '../assets/images/icon-cl.png'
import iconMax from '../assets/images/icon-max10.png'
import iconParlay from '../assets/images/icon-parlay.png'
import iconPick5 from '../assets/images/icon-pick5.png'
import icon1vs1 from '../assets/images/icon-1vs1.png'
import iconPrivate from '../assets/images/icon-private.png'
import iconRetar from '../assets/images/icon-retar.png'
import iconClose from '../assets/images/icon-close.png'
import iconPDF from '../assets/images/pdf-icon.png'

export function TokenExpiredPanel({
  isTokenExpired,
  logOut,
  userData,
  showLogin,
  hideLogin,
  showPanel,
  hidePanel,
  type,
}) {
  // if( isTokenExpired ){
  //   setTimeout( () => {
  //     console.log('Expired');
  //   } , 1000);
  // }
  let conditionalComponent = null;

  switch (type) {
    case 'expired':
      conditionalComponent = (
        <div className="panel-abonar">
          <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo failed `}>
            <span>Tú sesión ha expirado</span>
          </div>
          <div className="flex-row pickwin-panel">
            <div className="flex-col col-80 col-offset-10">
              <div className="flex-container justify align column text-center">
                <i className="ion-close-circled " />
                <strong>
                  {' '}
                  Si quieres reanudar tu sesión como {userData.nick} ingresa tu contraseña
                  nuevamente.
                </strong>
              </div>
            </div>
          </div>
          <div className="flex-row pickwin-panel">
            <Button className="flex-col col-40 col-offset-5 btn-pick btn-rojo">
              {' '}
              No, Gracias{' '}
            </Button>
            <Button className="flex-col col-40 col-offset-10 btn-pick "> Ingresar </Button>
          </div>
        </div>
      );
      break;
    case 'loggingOut':
      conditionalComponent = (
        <div className="panel-abonar">
          <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo failed `}>
            <span>Cerrando sesión </span>
          </div>
          <div className="flex-row pickwin-panel">
            <div className="flex-col col-80 col-offset-10">
              <div className="flex-container justify align column text-center">
                <i className="ion-ios-stopwatch-outline expiredTime" />
                <span> Te estamos redirigiendo. </span>
                <strong> Inicia sesión nuevamente para poder jugar. </strong>
              </div>
            </div>
          </div>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={showPanel} keyboard={false}>
      {conditionalComponent}
    </Modal>
  );
}


/**
   * This Function returns the amount of Prize that will be assigned to the user
   * It uses the rank to ssearch the equivalence of the prize
   */
const getAganar = function (entryData) {
  if (!entryData || !entryData.available || !entryData.pool_prizes || !entryData.ties_distribution || !entryData.rank) return '0'
  const { available, pool_prizes, ties_distribution, rank  } = entryData

  const maxPosition = _.max(pool_prizes, 'position').position

  let forStanding

  ties_distribution.forEach((tie) => {
    let rank = tie.rank;

    if (rank > maxPosition) {
      return;
    }

    let acc = 0;

    for (let i = rank; i < rank + tie.ties; i++) {
      let prize = pool_prizes[i - 1];

      if (!prize) {
        continue;
      }

      acc += prize.percentage
    }

    forStanding = (available * (acc / 10000)) / tie.ties;

    tie['prize_amount'] = forStanding;
  });

  const amount = ties_distribution.find((tie) => tie.rank === rank)?.prize_amount

  return amount ?? '0'
}

export function DisplayPremioMisQuinielas({ entry }) {
  const bolsaCurrency = entry.prize_currency;
  let currencyValue = null;
  let currencyStyle = null;

  const premio = getAganar(entry)

  switch (bolsaCurrency) {
    case 'ticket':
      currencyValue = entry.prize_amount;
      currencyStyle = {};
      break;
    case 'real':
      currencyValue = entry.prize_amount ? entry.prize_amount : premio;
      currencyStyle = { color: '#78b529' };
      break;
    case 'pickcoin':
      currencyValue = numeral(entry.prize_amount / 100).format('0,000.00');
      currencyStyle = { color: '#f9b13b' };
      break;
    default:
  }
  return (
    <span data-id={entry.id} className="bolsa flex-row row-no-padding row-center">
      <div className=" flex-col col-no-padding col-center" data-id={entry.id}>
        PREMIO:
      </div>
      <div className="flex-row row-no-padding row-center" data-id={entry.id} style={currencyStyle}>
        {numeral(currencyValue / 100).format('$0,000.00')}
      </div>
    </span>
  );
}

export function ErrorMessages({ label }) {
  // console.log('ErrorMessage :', this);
  // console.log('label :', label);
  return (
    <div className="flex-row">
      <div className="no-pools-available flex-container column align justify">
        <i className="alert ion-alert-circled" />
        <div className="text-center">{label}</div>
      </div>
    </div>
  );
}

export function DisplayBolsaMisQuinielas({ entry }) {
  const bolsaCurrency = entry.prize_currency;
  let currencyIcon = null;
  let currencyValue = null;
  let currencyStyle = null;
  let conditionalComponent = null;

  switch (bolsaCurrency) {
    case 'ticket':
      currencyIcon = ticketIcon;
      currencyValue = entry.prize_count;
      currencyStyle = {};
      break;
    case 'real':
      currencyIcon = billete;
      currencyValue = numeral(entry.available / 100).format('$0,000');
      currencyStyle = { color: '#78b529' };
      break;
    case 'pickcoin':
      currencyIcon = pickcoin;
      currencyValue = numeral(entry.available / 100).format('0,000');
      currencyStyle = { color: '#f9b13b' };
      break;
    case 'gift':
      currencyIcon = regalo;
      currencyValue = null;
      currencyStyle = null;
      break;
    default:
  }
  return (
    <span data-id={entry.id} className="bolsa flex-row row-center row-no-padding">
      <span className="flex-col" data-id={entry.id}>
        BOLSA:
      </span>
      <div className="flex-row row-no-padding row-center">
        <img data-id={entry.id} className={`pool-icons comision `} src={currencyIcon} />
        <span style={currencyStyle} data-id={entry.id}>
          {currencyValue}
        </span>
      </div>
    </span>
  );
}

export function DisplayBolsaScore({ poolInfo }) {
  const bolsaCurrency = poolInfo.prize_currency;
  let conditionalComponent = null;
  switch (bolsaCurrency) {
    case 'ticket':
      conditionalComponent = (
        <div className="bolsa-total">
          <img src={ticketIcon} className="score-icons" />
          <span className="bolsa-acumulada"> {poolInfo.available} </span>
        </div>
      );
      break;
    case 'real':
      conditionalComponent = (
        <div className="bolsa-total">
          <img src={billete} className="score-icons" />
          <span
            className="bolsa-acumulada"
            style={poolInfo.guaranteed_prize !== null ? { color: 'yellow', fontWeight: 'bold' } : null}
          >
            {numeral(poolInfo.available / 100).format('$0,0000')}
          </span>
        </div>
      );
      break;
    case 'pickcoin':
      conditionalComponent = (
        <div className="bolsa-total">
          <img src={pickcoin} className="score-icons" />
          <span
            className="bolsa-acumulada"
            style={poolInfo.guaranteed_prize !== null ? { color: 'yellow', fontWeight: 'bold' } : null}
          >
            {numeral(poolInfo.available / 100).format('0,0000')}
          </span>
        </div>
      );
      break;
    case 'referral':
      conditionalComponent = (
        <div className="bolsa-total">
          <img src={pickcoinPlata} className="score-icons" />
          <span
            className="bolsa-acumulada"
            style={poolInfo.guaranteed_prize !== null ? { color: 'yellow', fontWeight: 'bold' } : null}
          >
            {numeral(poolInfo.available / 100).format('0,0000')}
          </span>
        </div>
      );
      break;
    case 'gift':
      conditionalComponent = (
        <div className="bolsa-total">
          <img src={regalo} className="score-icons gift" />
        </div>
      );
      break;
    default:
  }

  return conditionalComponent;
}

export function DisplayBolsa({ poolInfo }) {
  const bolsaCurrency = poolInfo.prize_currency;
  let conditionalComponent = null;
  switch (bolsaCurrency) {
    case 'ticket':
      conditionalComponent = (
        <span data-id={poolInfo.id}>
          <img src={ticketIcon} className="pool-icons billete pool" />
          {poolInfo.available}
        </span>
      );
      break;
    case 'real':
      conditionalComponent = (
        <span
          data-id={poolInfo.id}
          style={poolInfo.guaranteed_prize !== null ? { color: '#FFF900' } : null}
        >
          {numeral(poolInfo.available / 100).format('$0,0000')}
        </span>
      );
      break;
    case 'pickcoin':
      conditionalComponent = (
        <span
          data-id={poolInfo.id}
          style={poolInfo.guaranteed_prize !== null ? { color: '#FFF900' } : null}
        >
          {numeral(poolInfo.available / 100).format('0,0000')}
        </span>
      );
      break;
    case 'gift':
      conditionalComponent = (
        <span data-id={poolInfo.id}>
          <img className="pool-icons pool" src={regalo} />
        </span>
      );
      break;
    default:
  }

  return conditionalComponent;
}

export function ErrorMessage({ showError, label }) {
  let conditionalComponent = null;
  if (showError) {
    conditionalComponent = (
      <div className="error-message flex-col text-center">
        <i className="ion-alert-circled" /> {label}
      </div>
    );
  }
  return conditionalComponent;
}

export function ErrorMessageValidation({ label, setDangeroulsy }) {
  let conditionalComponent = null;
  // console.log(label);
  if (label) {
    conditionalComponent = (
      <div className="flex-col error-message col-no-padding">
        {setDangeroulsy ? <div dangerouslySetInnerHTML={{ __html: label }} /> : label}
      </div>
    );
  }
  return conditionalComponent;
}

export function LoadingProcess({ show }) {
  let conditionalComponent = null;
  if (show) {
    conditionalComponent = (
      <div className="jornadaList flex-container justify align wrap ">
        <div className="flex-row row-no-padding">
          <div className="flex-container justify align">
            <i className="ion-load-a loading" />
          </div>
        </div>
      </div>
    );
  }
  return conditionalComponent;
}

export function InviteButtonScore({ getBranchLink, showInvite, updateBranchLink, dispatch }) {
  return (
    <div className="inviteFriends lower-button-container flex-col col-center ">
      <Button
        onClick={(e) => {
          console.log('Clicked');
          // e.preventDefault();
          let branchLink = getBranchLink().then((url) => {
            console.log('branchLink', url, showInvite);
            if (dispatch) {
              showInvite(dispatch);
              updateBranchLink(dispatch, url);
            } else {
              showInvite();
              updateBranchLink(url);
            }
          });
        }}
        className="invite-to-play desktop"
      >
        <div className="button-rules button-header-background1 button-header-radius" style={{padding: '4px 5px'}}>
          <span>Invitar Amigos</span>
          <img src={iconRetar} style={{width: '12px', height: 'auto'}} />
        </div>
      </Button>
    </div>
  );
}

export function CancelEntryButton({ cancel, dispatch }) {
  return (
    <div className="cancelarRegistro lower-button-container ">
      <div className='button-rules button-header-background1 button-header-radius' style={{margin: '0 auto'}}
      onClick={() => {
        if (dispatch) {
          cancel(dispatch);
        } else {
          cancel();
        }
      }}
      >
        <span className="btn-text"> Cancelar </span>
        {/* <i className="icon ion-close-circled" style={{color: 'red', fontSize: '12px'}} /> */}
        <img src={iconClose} style={{width: '14px', height: '14px'}} />
      </div>
    </div>
  );
}

export function BranchInviteButton({ userData, game, gameType }) {
  return (
    <div className="inviteFriends lower-button-container flex-col">
      <Button
        className="invite-to-play btn"
        onClick={() => {
          branchUtils.createLink(userData, game, gameType);
        }}
      >
        <i className="ion-social-whatsapp" />
      </Button>
    </div>
  );
}

export function MensajeMisPicks({ message }) {
  return (
    <div className="mensaje-barra">
      <i className="ion-alert-circled" />
      <span className="mensaje-text">{message}</span>
    </div>
  );
}

export function PartidosMisQuinielas({
  poolInfo,
  selectedEntryData,
  poolWeekSelected,
  entriesInfo,
  fixtures,
  fixtureProps,
  desempates,
  estado,
  updatePicks,
  logged,
  getWinningPickLive,
  id,
  poolWeeks,
}) {
  // console.log('Partidos Mis Quinielas',this);
  let conditionalComponent;
  let ss;
  let format;
  let tournamentInstance = poolInfo.pool.tournament_instances[0].id;
  if (poolInfo.pool !== undefined) {
    format = poolInfo.pool.point_format;
  } else {
    format = poolInfo.point_format;
  }
  let weekID;
  let entryID = selectedEntryData.id;
  if (poolWeekSelected === undefined) {
    if (poolInfo.week_id === null || poolInfo.week_id === '') {
      weekID = '';
    } else {
      weekID = poolInfo.week_id;
    }
  } else {
    weekID = poolWeekSelected;
  }
  let typeQuina = poolInfo.type;
  // let fixtures;
  let resultados;
  let numProps = entriesInfo.picks_props.length;
  let temporalProps = [];
  let obj_ = {};

  let listMultiplePropFixture = null;

  let listProps = [];
  let listPropsKeys;
  let listPropsTemp = [];

  if (poolInfo.format === 'mixed') {
    if (fixtures !== undefined) {
      listPropsTemp = fixtureProps;
      // console.log( fixtures );
      if (listPropsTemp.length > 0) {
        listPropsTemp.forEach((partidos, i) => {
          if (partidos.props.length > 1) {
            let tempProps = [];
            tempProps = partidos.props.map((prop) => prop);
            // console.log('Multiple Props in one fixture');
            // console.log(tempProps);
            tempProps.forEach((prop) => {
              // console.log('Multiple',prop);
              let tempObj = prop;
              let tempFixt = fixtures.find((fixt) => fixt.id === prop.fixture_id);
              tempObj['enabled'] = tempFixt.enabled;
              listProps.push(tempObj);
            });
          } else {
            let tempProps = [];
            tempProps = partidos.props.map((prop) => prop);
            tempProps.forEach((prop) => {
              let tempObj = prop;
              let tempFixt = fixtures.find((fixt) => fixt.id === prop.fixture_id);
              tempObj['enabled'] = tempFixt.enabled;
              listProps.push(tempObj);
            });
          }
        });
      }
    }
  }

  let listFixture = fixtures;
  // if(this.props.poolInfo.format === 'mixed'){weekID = '';}
  let listDesempates = desempates[weekID];
  // console.log('Desemmoates MisQuinielas',this.props.desempates, listDesempates, weekID);
  if (listDesempates === undefined) {
    if (desempates.length > 0) {
      weekID = fixtures[0].week_id;
      listDesempates = desempates[weekID];
      // console.log(listDesempates);
    }
  }
  if (estado) {
    console.log(
      'Partidos Full MisQuinielas',
      listFixture,
      listMultiplePropFixture,
      listProps,
      listDesempates
    );
    //Cargar lista
    /**
     * Picks Section
     * */
    listFixture = listFixture.map((fixture, i) => {
      // console.log(liveFixt, liveStats);
      let closingDate = moment(fixture.deadline);
      let nowDate = moment();
      let itPassedSec = closingDate.diff(nowDate, 'seconds');
      // console.log(closingDate, new Date());
      // console.log(itPassedSec, itPassed)
      let format24h = false;
      let itStarted = false;
      let isLive = false;
      if (itPassedSec < 3600) {
        format24h = true;
      }
      if (itPassedSec < 0) {
        // this.props.updateStartedFixture(fixture.id);
        itStarted = true;
        if (!fixture.finished) {
          isLive = true;
        }
      }
      return (
        <div className="row-props-container mis" key={fixture.id}>
          {itStarted ? (
            isLive ? (
              <span className="live-pool flex-container">
                <div className="flex-col col-20 col-no-padding live-label">Live!</div>
                <div className=" flex-col col-no-padding live-data ">
                  <div className="flex-container justify-end">
                    <span>
                      {fixture.stats && fixture.stats.hasOwnProperty('quarter')
                        ? fixture.stats.quarter !== null && fixture.stats.quarter !== ''
                          ? fixture.stats.quarter
                          : ''
                        : ''}
                    </span>
                    <span>
                      {fixture.stats && fixture.stats.hasOwnProperty('timer')
                        ? fixture.stats.timer !== null && fixture.stats.timer !== ''
                          ? fixture.stats.timer + '"'
                          : ''
                        : ''}
                      {fixture.stats && fixture.stats.hasOwnProperty('timer') ? (
                        fixture.stats.timer !== null && fixture.stats.timer !== '' ? (
                          <i className="ion-ios-timer" />
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </div>
              </span>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          <div className="row-props results flex-row row-no-padding" data-id={fixture.id}>
            <label
              id={`propFixture-${fixture.id}-home`}
              className={`pick-category flex-row row-no-padding local ${
                logged
                  ? getWinningPickLive(fixture.id, 'home') !== null
                    ? getWinningPickLive(fixture.id, 'home') === true
                      ? 'gano selected'
                      : 'perdio selected falso'
                    : ''
                  : ''
              }`}
            >
              <div
                id={`${fixture.id}-home`}
                className={`category-container flex-row row-no-padding`}
              >
                <div className="team-data flex-col">
                  <div className="radio-container">
                    <input
                      disabled={fixture.enabled ? false : true}
                      id={`propFixture-${fixture.id}-home`}
                      onClick={(e) => {
                        updatePicks(e);
                      }}
                      name={`propFixture-${fixture.id}${entriesInfo ? entriesInfo.id : ''}`}
                      type="radio"
                      value="home"
                    />
                    <div className={`radio-check ${fixture.enabled ? '' : 'disabled'}`} />
                  </div>
                </div>
                <div className="team-data flex-col">
                  {fixture.home_team.logo === null ? (
                    <span />
                  ) : (
                    <i className={`home ${fixtures[i].home_team.logo}`} />
                  )}
                </div>
                <div className="team-data flex-col">
                  <span style={{ padding: '0 3px' }}>
                    {fixture.home_team === null ? 'N/A' : fixtures[i].home_team.abbreviation}
                  </span>
                </div>
              </div>
            </label>
            {format === 'line' ? (
              <div id={`${fixture.id}-tie `} className={`pick-category flex-container linea`}>
                <div className="category-container flex-row row-no-padding">
                  <div className="item-linea local flex-col">
                    {fixtures[i].favorite === 'home' ? fixtures[i].advantage : null}
                  </div>
                  <div className="item-linea visitante flex-col">
                    {fixtures[i].favorite === 'away' ? fixtures[i].advantage : null}
                  </div>
                </div>
              </div>
            ) : (
              <label
                id={`propFixture-${fixture.id}-tie `}
                className={`pick-category flex-row row-no-padding empate ${
                  logged
                    ? getWinningPickLive(fixture.id, 'tie') !== null
                      ? getWinningPickLive(fixture.id, 'tie') === true
                        ? 'gano selected'
                        : 'perdio selected '
                      : ''
                    : ''
                }
                             `}
              >
                <div
                  id={`${fixtures[i].id}-tie `}
                  className={` flex-row category-container row-no-padding`}
                >
                  <div className="team-data flex-col">
                    <div className="radio-container">
                      <input
                        id={`propFixture-${fixture.id}-tie`}
                        disabled={fixture.enabled ? false : true}
                        onClick={(e) => {
                          updatePicks(e);
                        }}
                        name={`propFixture-${fixture.id}${entriesInfo ? entriesInfo.id : ''}`}
                        type="radio"
                        value="tie"
                      />
                      <div className={`radio-check ${fixture.enabled ? '' : 'disabled'}`} />
                    </div>
                  </div>
                  <div className="team-data flex-col">
                    {fixture.winning_pick === 'tie' ? <span /> : <span />}
                  </div>
                </div>
              </label>
            )}
            <label
              id={`propFixture-${fixture.id}-away`}
              className={`pick-category visitante flex-row row-no-padding ${
                this.props.logged
                  ? getWinningPickLive(fixtures[i].id, 'away') === true
                    ? 'gano selected'
                    : getWinningPickLive(this.props.fixtures[i].id, 'away') === false
                    ? 'perdio selected'
                    : ''
                  : ''
              }
                             `}
            >
              <div
                id={`${fixtures[i].id}-away`}
                className={` row-no-padding category-container flex-row`}
              >
                <div className="team-data flex-col">
                  <span style={{ padding: '0 3px' }}>
                    {fixtures[i].away_team === null ? 'N/A' : fixtures[i].away_team.abbreviation}
                  </span>
                </div>
                <div className="flex-col-team-data">
                  {fixtures[i].away_team.logo === null ? (
                    <div />
                  ) : (
                    <i className={`${fixtures[i].away_team.logo} away`} />
                  )}
                </div>
              </div>
              <div className="team-data">
                <div className="radio-container">
                  <input
                    disabled={fixture.enabled ? false : true}
                    id={`propFixture-${fixture.id}-away`}
                    onClick={(e) => {
                      updatePicks(e);
                    }}
                    name={`propFixture-${fixture.id}${entriesInfo ? entriesInfo.id : ''}`}
                    type="radio"
                    value="away"
                  />
                  <div className={`radio-check ${fixture.enabled ? '' : 'disabled'}`} />
                </div>
              </div>
            </label>
            {fixture.finished ? (
              <div className=" pick-category resultado">
                {<span>{`${fixture.score_home} - ${fixture.score_away}`}</span>}
              </div>
            ) : itStarted ? (
              isLive ? (
                <div className=" pick-category resultado">
                  {<span>{`${fixture.score_home} - ${fixture.score_away}`}</span>}
                </div>
              ) : (
                <div className=" pick-category resultado Not-started-live">
                  {format24h ? (
                    <div className="pick-category resultado 22">
                      <ClockHandler
                        id={fixture.id}
                        fixtureId={fixture.id}
                        type="row"
                        endDate={fixture.deadline}
                      />
                    </div>
                  ) : (
                    <span className="pick-category resultado 12 ">
                      <span className="cierre">
                        {moment(fixture.deadline).format('MM/D/YY @ H:mm')}
                      </span>
                    </span>
                  )}
                </div>
              )
            ) : (
              <span className="pick-category resultado  00">
                {format24h ? (
                  <div className="pick-category resultado 22">
                    <ClockHandler
                      fixtureId={fixture.id}
                      id={fixture.id}
                      type="row"
                      endDate={fixture.deadline}
                    />
                  </div>
                ) : (
                  <span className="pick-category resultado 12 ">
                    <span className="cierre">
                      {moment(fixture.deadline).format('MM/D/YY @ H:mm')}
                    </span>
                  </span>
                )}
              </span>
            )}
            <li className=" pick-category cierre">
              {poolInfo === null ? (
                <span> </span>
              ) : fixtures[i].finished ? (
                <span className="perdio">{'FINALIZÓ'}</span>
              ) : (
                <span>{`${moment(fixtures[i].deadline).format(' D MMM ')}`}</span>
              )}
            </li>
          </div>
        </div>
      );
    });
    /**
     * Props Section
     * */
    if (poolInfo.format === 'mixed') {
      // console.log(listProps);
      if (listProps.length > 1) {
        // console.log(listProps, listMultiplePropFixture);
        let counterProps = 0;
        listProps = listProps.map((prop, i) => {
          console.log('ListProps', prop, listPropsTemp);
          let currentProp = listPropsTemp.find((temp) => temp.id === prop.fixture_id);
          // let currentProp = listMultiplePropFixture[prop.fixture_id].props.find(tempProp => tempProp.id === prop.id);
          // console.log('Current PRops',currentProp, prop.enabled);
          if (prop.enabled) {
            return (
              <div className="row-props-container mis" key={i}>
                <FormGroup>
                  <ul className="lista-Props" data-id={prop.fixture_id}>
                    <ul data-id={entryID} className={`props-row ${entryID}`}>
                      <div className="titleProps row">{`${prop.name}  - [${currentProp.home_team.abbreviation} VS  ${currentProp.away_team.abbreviation}]`}</div>
                      <div className="props-data">
                        <label
                          id={`propPartido-${prop.id}-under`}
                          className={`prop-category ${
                            logged
                              ? utils.getWinningProp(prop.fixture_id, prop.id, 'under') !== null
                                ? utils.getWinningProp(prop.fixture_id, prop.id, 'under') === true
                                  ? 'gano selected '
                                  : 'perdio selected'
                                : ''
                              : ''
                          }`}
                        >
                          <li>
                            <div className="radio-container">
                              <input
                                disabled={
                                  listProps[i].enabled
                                    ? entriesInfo !== null
                                      ? false
                                      : poolInfo.pool.status === 'live'
                                      ? true
                                      : false
                                    : true
                                }
                                onClick={updatePicks}
                                id={`propPartido-${prop.id}-under`}
                                name={`propPartido-${prop.id}${entryID ? '-' + entryID : ''}`}
                                data-id={prop.id}
                                type="radio"
                                value={'under'}
                              />
                              <div
                                className={`radio-check ${
                                  listProps[i].enabled
                                    ? entriesInfo !== null
                                      ? ''
                                      : poolInfo.pool.status === 'live'
                                      ? 'disabled'
                                      : ''
                                    : 'disabled'
                                }`}
                              />
                            </div>
                            <span>{prop.boolean ? 'Si' : 'Menor'}</span>
                          </li>
                        </label>
                        <li>
                          {prop.boolean ? null : (
                            <span className="prop-value">
                              {prop.value === null ? 'PD' : prop.value}
                            </span>
                          )}
                        </li>
                        <label
                          id={`propPartido-${prop.id}-over`}
                          className={`prop-category ${
                            logged
                              ? utils.getWinningProp(prop.fixture_id, prop.id, 'over') !== null
                                ? utils.getWinningProp(prop.fixture_id, prop.id, 'over') === true
                                  ? 'gano'
                                  : 'perdio'
                                : ''
                              : ''
                          }`}
                        >
                          <li>
                            <span>{prop.boolean ? 'No' : 'Mayor'}</span>
                            <div className="radio-container">
                              <input
                                id={`propPartido-${prop.id}-over`}
                                disabled={
                                  listProps[i].enabled
                                    ? entriesInfo !== null
                                      ? false
                                      : poolInfo.pool.status === 'live'
                                      ? true
                                      : false
                                    : true
                                }
                                onClick={updatePicks}
                                name={`propPartido-${prop.id}${entryID ? '-' + entryID : ''}`}
                                data-id={id}
                                type="radio"
                                value={'over'}
                              />
                              <div
                                className={`radio-check ${
                                  listProps[i].enabled
                                    ? entriesInfo !== null
                                      ? ''
                                      : poolInfo.pool.status === 'live'
                                      ? 'disabled'
                                      : ''
                                    : 'disabled'
                                }`}
                              />
                            </div>
                          </li>
                        </label>
                      </div>
                    </ul>
                  </ul>
                </FormGroup>
              </div>
            );
          } else {
            return (
              <div className="row-props-container mis" key={i}>
                <ul className="row-partidos" data-id={prop.fixture_id}>
                  <li className="col-xs-12">
                    <li id={`${prop.fixture_id}-nombre`} className="desempate-category nombre">
                      <div className="category-container">
                        <span>
                          {`${prop.name} ${currentProp.home_team.abbreviation}-${currentProp.away_team.abbreviation}`}{' '}
                        </span>
                      </div>
                    </li>
                  </li>
                  <div className="col-xs-12 desempates-value-mis">
                    <li
                      id={`${prop.fixture_id}-value under`}
                      className={`desempate-category value ${
                        logged
                          ? utils.getWinningProp(prop.fixture_id, prop.id, 'under') !== null
                            ? utils.getWinningProp(prop.fixture_id, prop.id, 'under') === true
                              ? 'gano'
                              : 'perdio'
                            : Utils.getSelectedProp(prop.fixture_id.prop_id, 'under')
                            ? 'selected'
                            : ''
                          : ''
                      }`}
                    >
                      {prop.boolean ? <span>{'SI'}</span> : <span>{'MENOR'}</span>}
                    </li>
                    <li id={`${prop.fixture_id}-value `} className="desempate-category value">
                      {prop.boolean ? null : (
                        <span style={{ padding: '0 3px', color: '#E6D307' }}>{prop.value}</span>
                      )}
                    </li>
                    <li
                      id={`${prop.fixture_id}-value over_under`}
                      className={`desempate-category value ${
                        logged
                          ? utils.getWinningProp(prop.fixture_id, prop.id, 'over') !== null
                            ? utils.getWinningProp(prop.fixture_id, prop.id, 'over') === true
                              ? 'gano'
                              : 'perdio'
                            : Utils.getSelectedProp(prop.fixture_id.prop_id, 'over')
                            ? 'selected'
                            : ''
                          : ''
                      }`}
                    >
                      {prop.boolean ? <span>{'NO'}</span> : <span>{'MAYOR'}</span>}
                    </li>
                  </div>
                </ul>
              </div>
            );
          }
        });
        // console.log(listProps.length, listProps)
      } else if (listProps.length === 0) {
        listProps = null;
      } else {
        // console.log(listProps, listMultiplePropFixture);
        listProps = (
          <div className="row-props-container mis">
            <ul className="row-partidos" data-id={listProps.id}>
              <li id={`${listProps.id}-nombre`} className="desempate-category nombre">
                <div className="category-container">
                  {listProps[0].home_team === undefined ? (
                    <span>{`${listProps[0].props[0].name} `} </span>
                  ) : (
                    <span>
                      {`${listProps[0].props[0].name} ${listProps[0].home_team.abbreviation}-${listProps[0].away_team.abbreviation}`}{' '}
                    </span>
                  )}
                </div>
              </li>
              <li id={`${listProps.id}-value under`} className="desempate-category value">
                {listProps.boolean ? (
                  <span
                    className={listProps.winning_pick === 'under' ? 'gano' : 'perdio'}
                    style={{ padding: '0 3px' }}
                  >
                    {'SI'}
                  </span>
                ) : (
                  <span
                    className={listProps.winning_pick === 'under' ? 'gano' : 'perdio'}
                    style={{ padding: '0 3px' }}
                  >
                    {'MENOR'}
                  </span>
                )}
              </li>
              <li id={`${listProps.id}-value `} className="desempate-category value">
                {listProps.boolean ? null : (
                  <span style={{ padding: '0 3px', color: '#E6D307' }}>{listProps.value}</span>
                )}
              </li>
              <li id={`${listProps.id}-value over_under`} className="desempate-category value">
                {listProps.boolean ? (
                  <span
                    className={listProps.winning_pick === 'over' ? 'gano' : 'perdio'}
                    style={{ padding: '0 3px' }}
                  >
                    {'NO'}
                  </span>
                ) : (
                  <span
                    className={listProps.winning_pick === 'over' ? 'gano' : 'perdio'}
                    style={{ padding: '0 3px' }}
                  >
                    {'MAYOR'}
                  </span>
                )}
              </li>
            </ul>
          </div>
        );
      }
    }
    if (listDesempates !== undefined && listDesempates.length > 1) {
      listDesempates = listDesempates.map((des, i) => (
        <div className="row-props-container mis" key={i}>
          <ul className="row-partidos" data-id={des.id}>
            <li id={`${des.id}-nombre`} className="desempate-category nombre">
              <div className="category-container">
                <span>{des.label}</span>
              </div>
            </li>
            <li id={`${des.id}-value`} className="desempate-category value">
              <span style={{ padding: '0 3px' }}>{des.result.value}</span>
            </li>
          </ul>
        </div>
      ));
    } else if (listDesempates === 0 || listDesempates === undefined) {
      listDesempates = null;
    } else {
      let desempateValue =
        listDesempates[0].value === null ? listDesempates[0].result.value : listDesempates[0].value;
      listDesempates = (
        <div className="row-props-container mis">
          <ul className="row-partidos" data-id={listDesempates[0].id}>
            <li id={`${listDesempates[0].id}-nombre`} className="desempate-category nombre">
              <div className="category-container">
                <span>{listDesempates[0].label}</span>
              </div>
            </li>
            <li id={`${listDesempates[0].id}-value`} className="desempate-category value">
              <span style={{ padding: '0 3px' }}>
                {entriesInfo.user_tiebreakers.length === 0
                  ? 'N/D'
                  : entriesInfo.user_tiebreakers[0].value}
              </span>
            </li>
            <li id={`${listDesempates[0].id}-resultado`} className="desempate-category resultado">
              <span style={{ padding: '0 3px' }}>{desempateValue}</span>
            </li>
          </ul>
        </div>
      );
    }
    conditionalComponent = (
      <div
        className={`partidos-mis-quinielas ${poolInfo.type} ${
          poolInfo.days_until_close === -1 ? 'results' : 'fill'
        }`}
      >
        <SetQuinielaHeaders poolInfo={poolInfo} poolWeeks={poolWeeks} />
        {listFixture}
        {listProps !== null ? (
          <div>
            <div className="col-xs-12 title-quiniela mis sub">
              <span>Props</span>
            </div>
            {listProps}
          </div>
        ) : null}
      </div>
    );
  } else {
    conditionalComponent = <Loading label="..." />;
  }
  return conditionalComponent;
}
//36333.37471599999
export function TablaPosicionesMisQuinielas({
  followUser,
  followError,
  followSuccess,
  posiciones,
  tipoPremio,
  isLoaded,
  userData,
  poolInfo,
  entryId,
  entry,
  handleSelectedUser,
  getAganar,
  parlay,
  parlayDeadlines,
}) {
  // if (entryId === 1090136) {
  //   debugger
  // }
  // posiciones.items.sort(
  //   firstBy('rank', 1)
  //     .thenBy('total_possibly_correct', -1)
  // );
  // console.log('tablas Posiciones Mis Quinielas',this.props.posiciones.items.length);
  let listItems = [];
  let listUser = [];
  let rowUser;
  posiciones = posiciones.items;
  let newPosiciones = null;
  let isLive = false;
  // console.log('tipoPremio',tipoPremio);
  if (isLoaded) {
    // console.log('%c Entry Data', 'color:rgb(91, 186, 58); font-size:2em;');
    // console.log(this.props.entry);
    // console.log(posiciones);
    //Cargar lista de premios
    listUser = entry;

    let currencyPrizeIcon = null;

    switch (poolInfo.pool.prize_currency) {
      case 'real':
        currencyPrizeIcon = billete;
        break;
      case 'picoin':
        currencyPrizeIcon = pickcoins;
        break;
      case 'gift':
        currencyPrizeIcon = regalo;
        break;
      case 'ticket':
        currencyPrizeIcon = ticketIcon;
        break;
    }

    //chec bloks actives to parlay
    let activeBloks = 0;
    if (poolInfo.pool.parlay_flag !== null) {
      let d = new Date();
      let today = d.getTime();
      parlayDeadlines.map((e) => {
        let parlayDate = Date.parse(e);
        if (today > parlayDate) {
          activeBloks++;
        }
      })
    }

    listItems = posiciones.map((user, i) => {
      // FIXME: No entiendo que tiene que pasar aquí
      const followSuccess = false;
      const followError = false;
      // const followSuccess = !followSuccess
      //   ? false
      //   : followSuccess.find((success) => success.status && success.id === user.user_id);
      // const followError = !followError
      //   ? false
      //   : followError.find((error) => error.status && error.id === user.user_id);
      // console.log(followSuccess, followError)

      if (user.entry_id === entry.id) {
        // console.log( 'Std standing found this ');
        // console.log(user);
        // console.log( 'My Entry  ');
        // console.log(listUser);
        if (listUser) {
          listUser.standing.total_possibly_correct = user.total_possibly_correct;
          listUser.standing.rank = user.rank;
          listUser.standing.points = user.points;
          listUser.user_prize = user.user_prize;
          listUser.standing.user_prize = user.user_prize;
        }
      }

      // console.log(user.user_prize.description);
      let rank = user.rank;

      // let followSuccess = null ;
      // let followError = null ;
      // let followUser = ( userId ) => {
      //   console.log('MOCK', userId);
      // }

      let buttonFollowFriend = (followSuccess, followError, followUser, user) => {
        // console.log('Button add user: ',user );
        let conditionalComponent = null;
        if (
          (utils.isLogged() && user && user.user_id !== utils.getUserInfo().id) ||
          user.friend !== 0
        ) {
          if (!followSuccess && !followError) {
            conditionalComponent = (
              <div className="posiciones-category friend quina flex-container align justify">
                <span
                  className={` flex-row row-center btn-addFriend row-no-padding`}
                  onClick={() => {
                    handleSelectedUser(user);
                  }}
                >
                  <i className="ion-plus-round flex-col col-center"></i>
                </span>
              </div>
            );
          } else if (followSuccess) {
            conditionalComponent = (
              <div className="flex-container align justify friend quina position-category successFollow">
                <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
              </div>
            );
          } else if (followError) {
            conditionalComponent = (
              <div className="flex-container align justify friend quina position-category errorFollow">
                <i className="ion-close-round " style={{ color: '#e00034' }}></i>
              </div>
            );
          }
        }
        return conditionalComponent;
      };

      let parlayStyleAganar = "";
      if (poolInfo.pool.parlay_flag === true) {
        parlayStyleAganar = 'alive gano';
        if (user.parlay_elimination !== null) {
          parlayStyleAganar = 'alive perdio';
        }
      }

      return (
        <li
          key={user.entry_id}
          className={`position-item position-row ${
            utils.isLogged() && utils.getUserInfo().id === user.user_id && user?.entry?.number === entry?.number ? 'user' : ''
          }`}
        >
          <div className="posiciones-category col-center friends flex-col  col-10">
            {buttonFollowFriend(followSuccess, followError, followUser, user)}
          </div>
          <div className="posiciones-category rank flex-col col-5">
            <span>{user.rank}°</span>
          </div>
          <div className="posiciones-category jugador flex-col col-30">
            <span>
              {user.user.nick}
              {user.entry.number !== null ? <sup>[{user.entry.number}]</sup> : null}
            </span>
          </div>
          <div className="posiciones-category aciertos flex-col col-15">
            {poolInfo.pool.parlay_flag === null && (
              <span>{poolInfo.pool.closed ? user.points : user.total_possibly_correct}</span>
            ) || (
              <span>{poolInfo.pool.closed ? user.points : user.total_possibly_correct} / {activeBloks}</span>
            )}
          </div>
          <div className="posiciones-category flex-col col-10 currency">
            <span>
              <img
                src={currencyPrizeIcon}
                className={`pool-icons ${
                  poolInfo.pool.prize_currency === 'ticket' ? 'tickets' : ''
                }`}
                style={{height: '0.8em'}}
              />
            </span>
          </div>
          {poolInfo.pool.prize_currency === 'pickcoin' ? (
            isLive ? (
              <div className="posiciones-category a-ganar text-center">
                <div className=" flex-container justify align cant">
                  {numeral(user.user_prize.amount / 100).format('0,000')} PC
                </div>
              </div>
            ) : (
              <div className="posiciones-category a-ganar flex-col text-center col-30 ">
                <div className=" flex-container justify align cant">
                  {' '}
                  {numeral(user.user_prize.amount / 100).format('0,000')}
                  {' PC'}
                </div>
              </div>
            )
          ) : poolInfo.pool.prize_currency === 'ticket' ||
            poolInfo.pool.prize_currency === 'gift' ? (
            isLive ? (
              <div className="posiciones-category a-ganar flex-col col-30 ">
                <div className="flex-container justify align cant">
                  {user.user_prize.description !== null ? user.user_prize.description : '-'}
                </div>
              </div>
            ) : poolInfo.pool.status === 'upcoming' ? (
              <div className="posiciones-category a-ganar text-center">
                <span className="flex-container justify align"> - </span>
              </div>
            ) : (
              <div className="posiciones-category a-ganar flex-col col-30 text-center ">
                <div className=" flex-container justify align cant small">
                  {' '}
                  {user.user_prize.description}{' '}
                </div>
              </div>
            )
          ) : isLive ? (
            <div className={`posiciones-category a-ganar flex-col col-25 ${parlayStyleAganar}`}>
              <span className="flex-container justify align cant">
                {numeral(user.user_prize.amount / 100).format('$0,0000.00')}
              </span>
            </div>
          ) : (
            <div className={`posiciones-category a-ganar flex-col col-25 ${parlayStyleAganar}`}>
              <ISRTooltip
                availablePrize={numeral(user.user_prize.base_amount).format('$0,000.00')}
                moneyFormat={'$0,0000.00'}
                tax={user.user_prize.tax}
              />
              <div className="flex-container justify align cant">
                {numeral(user.user_prize.amount / 100).format('$0,0000.00')}
              </div>
            </div>
          )}
        </li>
      );
    });
    // console.log(this.props.entry);
    let listUsuarioPosicion = posiciones.find((user) => user.entry_id === entryId);
    // console.log(listUsuarioPosicion)
    listUser = entry;
    if (!listUser.hasOwnProperty('standing')) {
      listUser['standing'] = entry.standing;
    }
    // console.log('%c List User', 'color:rgb(91, 186, 58); font-size:2em;');
    // console.log(listUser);

    const p = getAganar(listUser.standing.rank) / 100

    // if (listUser.standing.rank === 20) {
    //   console.log('p', p, listUser)
    //   debugger
    // }

    if (listUser && utils.isLogged()) {
      rowUser = (
        <li className="position-item position-row user flex-row row-no-padding row-center">
          <div className="posiciones-category col-center friends flex-col"></div>
          <div className="posiciones-category rank flex-col col-10 col-no-padding">
            <span>{listUser.standing.rank}°</span>
          </div>
          <div className="posiciones-category flex-col col-30 jugador">
            <span>{`${userData.nick}`}</span>
            <span style={{ fontSize: 'smaller', margin: '7px 0px 0 2px' }}>{`${
              listUser.number !== null ? '[' + listUser.number + ']' : ''
            }`}</span>
          </div>
          <div className="posiciones-category aciertos flex-col col-15 col-no-padding">
            {poolInfo.pool.parlay_flag === null && (
              <span>
                {`${
                  poolInfo.pool.closed
                    ? listUser.standing.points
                    : listUser.standing.total_possibly_correct
                }`}{' '}
              </span>
            ) || (
              <span>
                {poolInfo.pool.closed
                    ? listUser.standing.points
                    : listUser.standing.total_possibly_correct} / {activeBloks}
              </span>
            )}
          </div>
          <div className="posiciones-category currency flex-col col-10 col-no-padding">
            <img
              src={currencyPrizeIcon}
              className={`pool-icons ${poolInfo.pool.prize_currency === 'ticket' ? 'tickets' : ''}`}
              style={{height: '0.8em'}}
            />
          </div>
          {poolInfo.pool.entry_currency === 'pickcoin' ? (
            <div className="posiciones-category a-ganar flex-col col-30">
              <div className="flex-container justify align cant">
                {listUser.user_prize !== undefined && listUser.user_prize !== null
                  ? numeral(listUser.user_prize.amount / 100).format('0,000') // ------
                  : numeral(getAganar(listUser.standing.rank) / 100).format('0,000')}
              </div>
            </div>
          ) : poolInfo.pool.prize_currency === 'ticket' ||
            poolInfo.pool.prize_currency === 'gift' ? (
            <div className="posiciones-category  flex-col col-30 a-ganar">
              <div
                className={`flex-container justify align cant ${
                  poolInfo.pool.prize_currency === 'ticket' ? 'small' : ''
                }`}
              >
                {poolInfo.pool.status === 'upcoming'
                  ? '-'
                  : listUser.user_prize !== undefined && listUser.user_prize.description !== null
                  ? listUser.user_prize.description
                  : '-'}
              </div>
            </div>
          ) : (
            <div className="posiciones-category a-ganar a-ganar flex-col col-30 ">
              <ISRTooltip
                availablePrize={numeral(
                  listUser.user_prize ? listUser.user_prize.base_amount / 100 : 0
                ).format('$0,000.00')}
                moneyFormat={'$0,0000.00'}
                tax={listUser.user_prize ? listUser.user_prize.tax : 0}
              />
              <div
                className={`flex-container justify align cant ${
                  poolInfo.pool.prize_currency === 'ticket' ? 'small' : ''
                }`}
              >
                {listUser.user_prize !== undefined && listUser.user_prize !== null
                  ? numeral(listUser.user_prize.amount / 100).format('$0,000.00')
                  : numeral(getAganar(listUser.standing.rank) / 100).format('$0,000.00')}
              </div>
            </div>
          )}
        </li>
      );
    } else {
      rowUser = null;
    }
    // console.log('User Data',typeof(listUser), listUser);
    // console.log(typeof(listItems),  listItems)
  } else {
    listUser = [];
  }
  let conditionalComponent;
  isLoaded
    ? (conditionalComponent = (
        <div className="position-list border-gradient">
          <div className="title-quiniela mis sub">
            <span>Posiciones</span>
          </div>
          <div>
            <ul className="lista-posiciones quiniela">
              <li className="position-header flex-row row-no-padding row-center ">
                <div className="flex-col posiciones-category "></div>
                <div className="posiciones-category rank flex-col col-15 col-no-padding">
                  <span>Mi pos</span>
                </div>
                <div className="posiciones-category jugador flex-col col-30 col-no-padding"></div>
                <div
                  className="posiciones-category aciertos flex-col col-15 col-no-padding"
                  style={{ justifyContent: 'center' }}  
                >
                  <span>Aciertos</span>
                </div>
                <div className="posiciones-category  flex-col col-10 col-no-padding">
                  <span />
                </div>
                <div className="posiciones-category a-ganar flex-col col-30">
                  {poolInfo.pool.closed ? (
                    <span className="flex-container justify align">Ganó</span>
                  ) : (
                    <span className="flex-container justify align"> A Ganar</span>
                  )}
                </div>
              </li>
              {rowUser}
              <li className="position-header flex-row row-no-padding row-center">
                <div className="posiciones-category flex-col col-center col-5">
                  <i
                    className="ion-ios-people flex-container justify align"
                    style={{ fontSize: '1.5em'}}
                  ></i>
                </div>
                <div className="posiciones-category  flex-col col-5 col-no-padding" style={{ margin: '0 4px 0 2px'}}>
                  <span>Pos</span>
                </div>
                <div className="posiciones-category  flex-col col-30 col-no-padding" style={{ margin: '0 4px 0 2px'}}>
                  <span>Jugador</span>
                </div>
                <div
                  className="posiciones-category  flex-col col-15 col-no-padding"
                  style={{ justifyContent: 'center' }}
                >
                  <span>Aciertos</span>
                </div>
                <div className="posiciones-category  flex-col col-10 col-no-padding">
                  <span />
                </div>
                <div className="posiciones-category  flex-col col-30 col-center">
                  {poolInfo.pool.closed ? (
                    <span className="flex-container justify align">Ganó</span>
                  ) : (
                    <span className="flex-container justify align">A Ganar</span>
                  )}
                </div>
              </li>
              {listItems.splice(0, 5)}
            </ul>
          </div>
        </div>
      ))
    : (conditionalComponent = <Loading label="Estamos buscando tu posición..." />);
  return conditionalComponent;
}

export function TablaPosicionesMisQuinielasWeekly({
  posiciones,
  tipoPremio,
  isLoaded,
  userData,
  poolInfo,
  entryId,
  followError_,
  followSuccess_,
  followUser,
  entry,
  getAganar,
  handleSelectedUser,
  type,
  currentWeek,
  premios,
  getAganarWeekly,
  poolWeeks
}) {

 const [myWeeklyPositions, setMyWeeklyPositions] = useState()

 useEffect(() => {
  API.getMyWeeklyStandings(poolInfo.pool.id, currentWeek.id, userData.id)
    .then(({ data }) => {
      if (data && data?.items && data?.items?.length) {
        setMyWeeklyPositions(data?.items[0])
      }
    })
 }, [currentWeek.id])

  let listItems = [];
  let listUser = {};
  let rowUser;
  posiciones = posiciones.items;

  let isLive = false;

  if (isLoaded) {
    listItems = posiciones.map((user) => {
      const followSuccess = !followSuccess_
        ? false
        : followSuccess.find((success) => success.status && success.id === user.user_id);
      const followError = !followError_
        ? false
        : followError.find((error) => error.status && error.id === user.user_id);

      // Find current user
      if (user.entry_id === entry.id) {
        console.log('Weekly standing found this ');
        // console.log(user);
        console.log('My Entry  ');
        console.log(listUser);
        console.log(user);
        // listUser = user;
        if (user) {
          listUser['standing'] = {};
          console.log(listUser);
          listUser.standing.total_possibly_correct = user.total_possibly_correct;
          listUser.standing.rank = user.rank;
          listUser.standing.points = user.points;
          listUser.user_prize = user.weekly_prize.amount;
          listUser.standing.user_prize = user.weekly_prize;
        }
      }

      let buttonFollowFriend = (followSuccess, followError, followUser, user) => {
        let conditionalComponent = null;

        if (
          (utils.isLogged() && user && user.user_id !== utils.getUserInfo().id) ||
          (user.user_id !== utils.getUserInfo().id && user.friend !== 0)
        ) {
          if (!followSuccess && !followError) {
            conditionalComponent = (
              <div className="posiciones-category friend quina flex-container align justify">
                <span
                  className={` flex-row row-center btn-addFriend row-no-padding`}
                  onClick={() => {
                    followUser(user.user_id);
                  }}
                >
                  <i className="ion-plus-round flex-col col-center"></i>
                </span>
              </div>
            );
          } else if (followSuccess) {
            conditionalComponent = (
              <div className="flex-container align justify friend quina position-category successFollow">
                <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
              </div>
            );
          } else if (followError) {
            conditionalComponent = (
              <div className="flex-container align justify friend quina position-category errorFollow">
                <i className="ion-close-round " style={{ color: '#e00034' }}></i>
              </div>
            );
          }
        }

        return conditionalComponent;
      };


      // Other users list
      return (
        <li
          key={user.entry_id}
          className={`position-item position-row 
                ${utils.isLogged && utils.getUserInfo().id === user.user_id && user?.entry?.number === entry?.number ? 'user' : ''}
                `}
        >
          <div className="posiciones-category col-center friends flex-col  col-10">
            {buttonFollowFriend(followSuccess, followError, followUser, user)}
          </div>
          <div className="posiciones-category rank flex-col col-5">
            <span>{user.rank}°</span>
          </div>
          <div className="posiciones-category jugador flex-col col-30">
            <span>
              {user.user.nick}
              {user.entry.number !== null ? <sup>[{user.entry.number}]</sup> : null}
            </span>
          </div>
          <div className="posiciones-category aciertos flex-col col-15">
            <span>{`${user.total_possibly_correct}`} </span>
          </div>
          <div className="posiciones-category flex-col col-10 currency">
            <span>
              <img
                src={poolInfo.pool.entry_currency === 'real' ? billete : null}
                className={`pool-icons ${
                  poolInfo.pool.prize_currency === 'ticket' ? 'tickets' : ''
                }`}
                style={{height: '0.8em'}}
              />
            </span>
              
          </div>
              
          {poolInfo.pool.entry_currency === 'pickcoin' ? (
            isLive ? (
              <div className="posiciones-category a-ganar flex-col col-25">
                <span className="flex-container justify align cant">
                  {numeral(user.weekly_prize.amount / 100).format('0,000')}
                </span>
                <small style={{ display: 'flex' }}>PC</small>
              </div>
            ) : (
              <div className="posiciones-category a-ganar flex-col col-25">
                <span className="flex-container justify align cant">
                  {numeral(user.weekly_prize.amount / 100).format('0,000')}
                </span>
                PC
              </div>
            )
          ) : isLive ? (
            <div className="posiciones-category a-ganar flex-col col-25">
              <ISRTooltip
                availablePrize={numeral(
                  user?.weekly_prize?.base_amount ? user?.weekly_prize?.base_amount / 100 : 0
                ).format('$0,000.00')}
                moneyFormat={'$0,0000.00'}
                tax={user?.weekly_prize?.base_amount ? user?.weekly_prize?.tax : 0}
              />
              <span className="flex-container justify align cant">
                {numeral(user.weekly_prize.amount / 100).format('$0,000.00')}
              </span>
            </div>
          ) : (
            // Money Real
            <div className="posiciones-category a-ganar flex-col col-25">
              <ISRTooltip
                availablePrize={numeral(
                  user?.weekly_prize?.base_amount ? user?.weekly_prize?.base_amount / 100 : 0
                ).format('$0,000.00')}
                moneyFormat={'$0,0000.00'}
                tax={user?.weekly_prize?.base_amount ? user?.weekly_prize?.tax : 0}
              />
              <span className="flex-container justify align cant">
                {numeral(user.weekly_prize.amount / 100).format('$0,000.00')}
              </span>
            </div>
          )}
        </li>
      );
    });

    // Own active user (logged in)
    listUser = entry;
    if (myWeeklyPositions) {
      rowUser = (
        <li className="position-item position-row user flex-row row-no-padding row-center">
          <div className="posiciones-category col-center friends flex-col"></div>
          <div className="posiciones-category rank flex-col col-10 col-no-padding">
            <span>{myWeeklyPositions?.rank}°</span>
          </div>
          <div className="posiciones-category flex-col col-30 jugador">
            <span>{`${myWeeklyPositions?.user?.nick}`}</span>
            <span style={{ fontSize: 'smaller', margin: '7px 0px 0 2px' }}>
            {(entry?.number !== undefined && entry?.number !== null) ? `[${entry?.number}]` : ''}
            </span>
          </div>
          <div className="posiciones-category aciertos flex-col col-15 col-no-padding">
            <span>{poolInfo.pool.closed
              ? myWeeklyPositions?.points
              : myWeeklyPositions?.total_possibly_correct}
            </span>
          </div>
          <div className="posiciones-category currency flex-col col-10 col-no-padding">
            <span>
              <img
                src={poolInfo.pool.entry_currency === 'real' ? billete : null}
                className={`pool-icons ${
                  poolInfo.pool.prize_currency === 'ticket' ? 'tickets' : ''
                }`}
                style={{height: '0.8em', marginBottom: 3 }}
              />
            </span>
          </div>

          {poolInfo.pool.entry_currency === 'pickcoin' ? (
            <div className="posiciones-category a-ganar a-ganar flex-col col-30">
              <span className="cant">
                {myWeeklyPositions?.weekly_prize !== undefined
                  ? numeral(myWeeklyPositions?.weekly_prize?.amount / 100).format('0,000')
                  : numeral(getAganar(myWeeklyPositions?.rank)).format('0,000')}
              </span>
            </div>
          ) : (
            <div className="posiciones-category a-ganar a-ganar flex-col col-30">
               <ISRTooltip
                availablePrize={numeral(
                  myWeeklyPositions?.weekly_prize?.base_amount ? myWeeklyPositions?.weekly_prize?.base_amount / 100 : 0
                ).format('$0,000.00')}
                moneyFormat={'$0,0000.00'}
                tax={myWeeklyPositions?.weekly_prize?.base_amount ? myWeeklyPositions?.weekly_prize?.tax : 0}
              />
              <span className="flex-container justify align cant">
                {myWeeklyPositions?.weekly_prize !== undefined
                  ? numeral(myWeeklyPositions?.weekly_prize?.amount / 100).format('$0,000.00')
                  : numeral(getAganar(myWeeklyPositions?.rank)).format('$0,000.00')}
              </span>
            </div>
          )}
        </li>
      );
    } else {
      rowUser = null;
    }
  } else {
    listUser = [];
  }
  let conditionalComponent

  (isLoaded && myWeeklyPositions) 
    ? (conditionalComponent = (
        <div className="position-list border-gradient">
          <div className="title-quiniela mis sub">
            <span>Posiciones</span>
          </div>
          <div
            className={`overflow overflow-y ${
              type === 'lobby' ? 'overflow-300' : 'overflow-y-full'
            }`}
          >
            <ul className="lista-posiciones quiniela">
              <li className="position-header flex-row row-no-padding row-center">
                <div className="posiciones-category rank flex-col col-15 col-no-padding">
                  <span>Mi pos</span>
                </div>
                <div className="posiciones-category jugador flex-col col-30 col-no-padding"></div>
                <div
                  className="posiciones-category aciertos flex-col col-15 col-no-padding"
                  style={{ justifyContent: 'center' }}
                >
                  <span>Aciertos</span>
                </div>
                <div className="posiciones-category  flex-col col-10 col-no-padding">
                  <span />
                </div>
                <div className="posiciones-category a-ganar flex-col col-30" style={{ justifyContent: 'center'}}>
                  {poolInfo.pool.closed ? <span>Ganó</span> : <span>A Ganar</span>}
                </div>
              </li>

              {rowUser}
              <li className="position-header flex-row row-no-padding row-center">
                <div className="posiciones-category flex-col col-center col-5">
                  <i
                    className="ion-ios-people flex-container justify align"
                    style={{ fontSize: '1.5em' }}
                  ></i>
                </div>
                <div className="posiciones-category flex-col col-5 col-no-padding" style={{ margin: '0px 4px 0px 2px' }}>
                  <span>Pos</span>
                </div>
                <div className="posiciones-category flex-col col-30 col-no-padding" style={{ margin: '0px 4px 0px 2px' }}>
                  <span>Jugador</span>
                </div>
                <div
                  className="posiciones-category flex-col col-15 col-no-padding"
                  style={{ justifyContent: 'center' }}
                >
                  <span>Aciertos</span>
                </div>
                <div className="posiciones-category flex-col col-10 col-no-padding">
                  <span />
                </div>
                <div className="posiciones-category  flex-col col-30 col-center">
                  <span className='flex-container justify align'>{poolInfo.pool.closed ?'Ganó' : 'A Ganar'}</span>
                </div>
              </li>
              {listItems.splice(0, 5)}
            </ul>
          </div>
        </div>
      ))
    : (conditionalComponent = <Loading label="Estamos buscando tu posición..." />);
  return conditionalComponent;
}

export function PicksPartidos({
  fixtures,
  poolInfo,
  isLoaded,
  updatePicks,
  getWinningPickLive,
  entriesInfo,
  type,
  id,
  updateStartedFixture,
  poolWeeks,
}) {
  let selectedEntryData;
  let entryId;
  let listProps = fixtures;

  let pointFormat = poolInfo.pool.point_format;
  let tournamentInstance = poolInfo.pool.tournament_instances[0].id;

  if (isLoaded) {
    // console.log( utils.isSuPick( ) );
    // console.log(this.props.fixtures, this.props.liveFixturesData)
    listProps = listProps.map((fixture, i) => {
      let isDisabled = !fixture.enabled || (utils.isLogged() && utils.isSuPick());

      let closingDate = moment(fixture.deadline);
      let nowDate = moment();
      let itPassedSec = closingDate.diff(nowDate, 'seconds');
      // console.log(closingDate, new Date());
      // console.log(itPassedSec, itPassedSec < 86400)
      let format24h = false;
      let format1h = false;
      let itStarted = fixture.sort_status === 0;
      let isLive = fixture.sort_status === 0;

      if (itPassedSec < 3600) {
        format1h = true;
      }
      if (itPassedSec < 86400) {
        format24h = true;
      }
      if (itPassedSec < 0) {
        // console.log(itPassed)
        // this.props.updateStartedFixture(fixture.id);
        if (!fixture.finished) {
          isLive = true;
        }
      }

      return (
        <div className="row-props-container" key={fixture.id}>
          {isLive ? (
            <span className="live-pool flex-container">
              <div className="flex-col col-20 col-no-padding live-label">Live!</div>
              <div className=" flex-col col-no-padding live-data ">
                <div className="flex-container justify-end">
                  <span>
                    {fixture.stats && fixture.stats.hasOwnProperty('quarter')
                      ? fixture.stats.quarter !== null && fixture.stats.quarter !== ''
                        ? fixture.stats.quarter
                        : ''
                      : ''}
                  </span>
                  <span>
                    {fixture.stats && fixture.stats.hasOwnProperty('timer')
                      ? fixture.stats.timer !== null && fixture.stats.timer !== ''
                        ? fixture.stats.timer + '"'
                        : ''
                      : ''}
                    {fixture.stats && fixture.stats.hasOwnProperty('timer') ? (
                      fixture.stats.timer !== null && fixture.stats.timer !== '' ? (
                        <i className="ion-ios-timer" />
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            </span>
          ) : (
            ''
          )}
          <div className={`row-props results flex-row row-no-padding`} data-id={fixture.id}>
            <label
              id={`propFixture-${fixture.id}-home`}
              onClick={() => {
                updatePicks('propFixture', 'home', fixture.id);
              }}
              className={`pick-category local flex-container ${
                fixture.suspended
                  ? null
                  : getWinningPickLive(fixture.id, 'home') !== null
                  ? getWinningPickLive(fixture.id, 'home') === true
                    ? 'gano selected'
                    : 'perdio selected'
                  : null
              }`}
              value="home"
            >
              <div id={`${fixture.id}-home`} className="pick-category flex-row row-no-padding">
                <div className="category-container flex-row row-no-padding">
                  <div className="flex-col col-20 team-data">
                    <div className="radio-container">
                      <input
                        disabled={isDisabled}
                        id={`propFixture-${fixture.id}-home`}
                        name={`propFixture-${fixture.id}${entriesInfo ? entriesInfo.id : ''}`}
                        type="radio"
                        value="home"
                      />
                      <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
                    </div>
                  </div>
                  <div className="flex-col col-20 team-data">
                    {fixture.home_team.logo === null ? (
                      <span className={`home`} />
                    ) : (
                      <i className={` ${fixture.home_team.logo} home`} />
                    )}
                  </div>
                  <div className="flex-col team-data col-60">
                    <span className="container-team-record overflow-words">
                      <span className="team-name">
                        {!fixture.home_team.short_name
                          ? fixture.home_team.name
                          : fixture.home_team.short_name}
                      </span>
                      {type === 'quiniela' ? (
                        poolInfo.pool.sport.allow_ties === 1 &&
                        fixture.home_team.team_stats !== null ? (
                          <span className="record-team soccer home">
                            <span className="win">[{fixture.home_team.team_stats.win} </span>
                            <span>-</span>
                            <span className="lost">{fixture.home_team.team_stats.lost} </span>
                            <span>-</span>
                            <span className="tie">{fixture.home_team.team_stats.ties}]</span>
                          </span>
                        ) : fixture.home_team.team_stats !== null ? (
                          <span className="record-team soccer home">
                            <span className="win">[{fixture.home_team.team_stats.win} </span>
                            <span>-</span>
                            <span className="lost">{fixture.home_team.team_stats.lost}] </span>
                          </span>
                        ) : null
                      ) : null}
                      {!fixture.period ? null : (
                        <small style={{ fontWeight: 'bold' }}>{fixture.period}</small>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </label>
            {pointFormat === 'natural' ? (
              poolInfo.pool.sport.allow_ties === 1 ? (
                <label
                  id={`propFixture-${fixture.id}-tie`}
                  onClick={() => {
                    updatePicks('propFixture', 'tie', fixture.id);
                  }}
                  className={`pick-category empate   ${
                    fixture.suspended
                      ? null
                      : getWinningPickLive(fixture.id, 'tie') !== null
                      ? getWinningPickLive(fixture.id, 'tie') === true
                        ? 'gano selected'
                        : 'perdio selected'
                      : null
                  }
                             `}
                >
                  <div key={`${fixture.id}-tie`} className="pick-category ">
                    <div className="category-container flex-row row-no-padding">
                      <div className="flex-col team-data">
                        <div className="radio-container">
                          <input
                            id={`propFixture-${fixture.id}-tie`}
                            disabled={isDisabled ? true : false}
                            name={`propFixture-${fixture.id}${entriesInfo ? entriesInfo.id : ''}`}
                            data-id-fixture={id}
                            type="radio"
                            value={'tie'}
                          />
                          <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              ) : (
                <div id={`propFixture-${fixture.id}-empate`} className="pick-category linea ">
                  <div className="category-container flex-row row-no-padding">{null}</div>
                </div>
              )
            ) : (
              <div id={`propFixture-${fixture.id}-empate`} className="pick-category linea">
                {fixture.favorite === null ? (
                  <div className="category-container flex-row row-no-padding">PD</div>
                ) : (
                  <div className="category-container flex-row row-no-padding">
                    <div className="item-linea flex-col team-data local">
                      {fixture.favorite === 'home' ? fixture.advantage : null}
                    </div>
                    <div className="item-linea flex-col visita">
                      {fixture.favorite === 'away' ? fixture.advantage : null}
                    </div>
                  </div>
                )}
              </div>
            )}
            <label
              id={`propFixture-${fixture.id}-away`}
              onClick={() => {
                updatePicks('propFixture', 'away', fixture.id);
              }}
              className={`pick-category visitante  flex-container ${
                fixture.suspended
                  ? null
                  : getWinningPickLive(fixture.id, 'away') !== null
                  ? getWinningPickLive(fixture.id, 'away') === true
                    ? 'gano selected'
                    : 'perdio selected'
                  : null
              }
                         `}
            >
              <div key={`${fixture.id}-away`} className="pick-category flex-container">
                <div className="category-container flex-row row-no-padding">
                  <div className="flex-col team-data col-60">
                    <span className="container-team-record overflow-words">
                      <span className="team-name">
                        {!fixture.away_team.short_name
                          ? fixture.away_team.name
                          : fixture.away_team.short_name}
                      </span>
                      {type === 'quiniela' ? (
                        poolInfo.pool.sport.allow_ties === 1 &&
                        fixture.away_team.team_stats !== null ? (
                          <span className="record-team soccer home">
                            <span className="win">[{fixture.away_team.team_stats.win} </span>
                            <span>-</span>
                            <span className="lost">{fixture.away_team.team_stats.lost} </span>
                            <span>-</span>
                            <span className="tie">{fixture.away_team.team_stats.ties}]</span>
                          </span>
                        ) : fixture.away_team.team_stats !== null ? (
                          <span className="record-team soccer home">
                            <span className="win">[{fixture.away_team.team_stats.win} </span>
                            <span>-</span>
                            <span className="lost">{fixture.away_team.team_stats.lost}] </span>
                          </span>
                        ) : null
                      ) : null}
                      {!fixture.period ? null : (
                        <small style={{ fontWeight: 'bold' }}>{fixture.period}</small>
                      )}
                    </span>
                  </div>
                  <div className="team-data col-20 flex-col">
                    {fixture.away_team.logo === null ? (
                      <span />
                    ) : (
                      <i className={` ${fixture.away_team.logo} away`} />
                    )}
                  </div>
                  <div className="flex-col col-20 team-data">
                    <div className="radio-container">
                      <input
                        id={`propFixture-${fixture.id}-away`}
                        disabled={isDisabled ? true : false}
                        name={`propFixture-${fixture.id}${entriesInfo ? entriesInfo.id : ''}`}
                        data-id-fixture={id}
                        type="radio"
                        value={'away'}
                      />
                      <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
                    </div>
                  </div>
                </div>
              </div>
            </label>
            {fixture.finished ? (
              <div className=" pick-category resultado">
                {<span>{`${fixture.score_home} - ${fixture.score_away}`}</span>}
              </div>
            ) : itStarted ? (
              <div className=" pick-category resultado">
                {<span>{`${fixture.score_home} - ${fixture.score_away}`}</span>}
              </div>
            ) : (
              <span className="pick-category resultado  00">
                {format24h && !format1h && type === 'misQuinielas' ? (
                  <span className="flex-container column justify align">
                    <span className="cierre fecha" style={{ color: '#e00034' }}>
                      {moment(fixture.deadline).format('DD MMM')}
                    </span>
                    <span className="cierre fecha" style={{ color: '#e00034' }}>
                      {moment(fixture.deadline).format('H:mm')}
                    </span>
                  </span>
                ) : format24h || (format1h && this.props.type === 'misQuinielas') ? (
                  <div className="pick-category 22">
                    <ClockHandler
                      id={fixture.id}
                      type="row"
                      updateStartedFixture={this.props.updateStartedFixture}
                      fixtureId={fixture.id}
                      endDate={fixture.deadline}
                    />
                  </div>
                ) : format24h ? (
                  <span className="pick-category  12 flex-container justify align column">
                    <span className="cierre fecha">
                      {moment(fixture.deadline).format('DD MMM')}
                    </span>
                    <span className="cierre fecha">{moment(fixture.deadline).format('H:mm')}</span>
                  </span>
                ) : (
                  <span className="flex-container justify align column">
                    <span className="cierre fecha">
                      {moment(fixture.deadline).format('DD MMM')}
                    </span>
                    <span className="cierre fecha">{moment(fixture.deadline).format('H:mm')}</span>
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      );
    });
  }
  let conditionalComponent;
  isLoaded
    ? (conditionalComponent = (
        <Form className={' 1'}>
          <div>
            <SetQuinielaHeaders poolInfo={poolInfo} poolWeeks={poolWeeks} />
          </div>
          {listProps}
        </Form>
      ))
    : (conditionalComponent = <Loading label="..." />);
  return conditionalComponent;
}

export function Desempates({
  desempates,
  fixtures,
  isLoaded,
  poolInfo,
  poolWeeks,
  poolWeekSelected,
  selectedEntry,
  selectedEntryData,
  updateTieData,
  tiesPicks,
}) {
  let conditonalComponent;
  // console.log( '%c Desempates Component', ' background:#7BD900;font-size:20px;' );
  // console.log(arguments);
  // console.log(selectedEntry, selectedEntryData);
  // console.log(this);
  let desempates_ = desempates;
  let weekID;
  let entryID = null;

  //  let isDisabled = desempates.pool.closed || utils.isLogged() && utils.isSuPick( )
  let myDesempates = [];
  if (selectedEntryData !== undefined && selectedEntryData !== null) {
    //  console.log(selectedEntryData);
    myDesempates = selectedEntryData.user_tiebreakers;
  } else if (selectedEntryData !== undefined && selectedEntryData !== null) {
    //  console.log( selectedEntryData);
    myDesempates = selectedEntryData.user_tiebreakers;
  } else {
    // console.log('NOT');
    myDesempates = [];
  }

  if (selectedEntry !== undefined || selectedEntry !== null) {
    entryID = selectedEntry;
  }
  // console.log('entryID', entryID);

  /**
   * Set WeekId for Full Quinielas to handle ties correctly
   */
  if (poolInfo.pool.type === 'week' && poolInfo.pool.week_id !== null) {
    desempates = desempates_[poolInfo.pool.week_id];
    weekID = poolInfo.pool.week_id;
  }
  if (poolInfo.pool.type === 'week' && poolInfo.pool.week_id === null) {
    var keys = Object.keys(desempates);
    var first = keys[0];
    desempates = desempates[first];
    weekID = first;
  }
  if (poolInfo.pool.type === 'full') {
    if (poolWeekSelected !== null && desempates.hasOwnProperty(poolWeekSelected)) {
      desempates = desempates[poolWeekSelected];
      weekID = poolWeekSelected;
    }
    if (desempates && desempates.hasOwnProperty('')) {
      desempates = desempates[''];
    }
  }

  // console.log('------>', weekID, desempates);
  if (isLoaded) {
    // console.log('%c %s %o', 'background:#009BF5; font-size:20px;', 'Desempates', desempates);
    if (!desempates) {
      desempates = null;
    } else {
      /*************************************************************************
       * Map each Tie to create a list of ties depending his type and config
       *************************************************************************/
      desempates = desempates.map((desempate, i) => {
        let fixtureRef = desempate.config_parsed.fixture ? desempate.config_parsed.fixture : null;
        let currentFixture = null;
        let tieEnabled;
        tieEnabled = desempate.enabled;

        /*************************************************************
         * If the tie isn enabled show the value selected by the user
         **************************************************************/
        if (!tieEnabled) {
          let weekGoalsValue = [];
          let teamLabel = '';
          let fullLabel = desempate.label;
          // console.log(tiesPicks)
          // let currentTie = myDesempates.find(myDesempate => {
          //   // console.log(myDesempate.tiebreaker_id, desempate.id)
          //   if (myDesempate.tiebreaker_id === desempate.id) {
          //     return myDesempate;
          //   }
          // });
          let currentTie = tiesPicks[desempate.id];

          // console.log(currentTie);
          /********************************************************
           *Special catch to show label correctly in a specific case
           ***********************************************************/
          if (
            (fullLabel[fullLabel.length - 2] === '%') &
            (fullLabel[fullLabel.length - 1] === 's')
          ) {
            fullLabel = 'Puntos hechos por %s';
          }
          // console.log(desempate.config_parsed.min, desempate.config_parsed.max);
          for (let i = desempate.config_parsed.min; i <= desempate.config_parsed.max; i++) {
            weekGoalsValue.push(i);
          }
          // console.log('%c NUMBER', 'background:#36A121;font-size:30px;');
          // console.log(myDesempates);
          if (desempate.config_parsed.team && desempate.config_parsed.team === 'home') {
            fullLabel = fullLabel
                .replace('%s', fixtures[fixtures.length - 1].home_team.abbreviation );
            // teamLabel = fixtures[fixtures.length - 1].home_team.abbreviation;
            // fullLabel = fullLabel + teamLabel;
          }
          if (desempate.config_parsed.team && desempate.config_parsed.team === 'away') {
            fullLabel = fullLabel
                .replace('%s', fixtures[fixtures.length - 1].away_team.abbreviation );
            // teamLabel = fixtures[fixtures.length - 1].away_team.abbreviation;
            // fullLabel = fullLabel + teamLabel;
          }
          let myTie = null;
          if (desempate.type === 'score') {
            myTie =
              !currentTie && currentTie !== '0'
                ? 'N/D - N/D'
                : currentTie.score_home + '-' + currentTie.score_away;
          } else if (desempate.type === 'team') {
            let totalTeams = {};
            fixtures.forEach((fixture) => {
              totalTeams[fixture.home_team.id] = fixture.home_team;
              totalTeams[fixture.away_team.id] = fixture.away_team;
            });

            myTie =
              !currentTie || !currentTie.value || !totalTeams[currentTie.value]
                ? 'N/D'
                : totalTeams[currentTie.value];
          } else {
            // console.log(currentTie);
            if (myDesempates.length === 1) {
              if (currentTie || currentTie !== 0) {
                if (
                  currentTie.hasOwnProperty('value') &&
                  currentTie.value !== 0 &&
                  currentTie.value
                ) {
                  if (currentTie.value === null) {
                    console.log('ND');
                    myTie = 'N/D';
                  } else {
                    myTie = currentTie.value;
                  }
                } else if (currentTie.hasOwnProperty('value') && currentTie.value === 0) {
                  myTie = 0;
                } else {
                  myTie = 'N/D';
                }
              } else {
                myTie = 'N/D';
              }
            } else {
              if (currentTie || currentTie !== 0) {
                if (currentTie.hasOwnProperty('value') && currentTie.value !== 0) {
                  if (currentTie.value === null) {
                    myTie = 'N/D';
                  } else {
                    myTie = currentTie.value;
                  }
                } else if (currentTie.hasOwnProperty('value') && currentTie.value === 0) {
                  myTie = 0;
                }
              } else {
                myTie = 'N/D';
              }
            }
            // console.log('currentTie',currentTie);
            // console.log('myDesempates',myDesempates);
            // console.log('myTie',myTie);
          }
          return (
            <li key={desempate.id + i} className="desempate-element">
              <div className="desempate-container row">
                <div className="col-xs-6 desempate-label">
                  <span>{fullLabel}</span>
                </div>
                <div className="col-xs-6 desempate-field">
                  {desempate.type === 'team' && (
                    <div className="flex-col" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.5em' }}>
                        <div className="team-data flex-col col-20">
                          {!myTie.logo ? <span></span> : <i className={`${myTie.logo} home`} style={{ width: '27px', height: '32px', display: 'block'}}></i>}
                        </div>
                        <span className="container-team-record overflow-words" style={{ flex: 'initial' }}>
                        <span className="team-name">{myTie.abbreviation}</span>
                        {myTie.team_stats ? (
                          <span className="record-team soccer away">
                            [{myTie.team_stats.win}-{myTie.team_stats.ties}-{myTie.team_stats.lost}]
                          </span>
                        ) : null}
                      </span>
                    </div>
                  )}
                  {desempate.type !== 'team' || typeof myTie === 'string' && (
                    <span className="miDesempate">{myTie}</span>
                  )}
                </div>
              </div>
            </li>
          );
        } else {
          /**********************************************************************
           * If the tie is enabled, show if with his custom config and elements
           **********************************************************************/
          /*********************
           * Type: Week Goals
           **********************/
          if (desempate.type === 'week_goals') {
            let weekGoalsValue = [];
            // console.log('%c Week Goals Value','background:#36A121');
            // console.log(desempate.config_parsed.min, desempate.config_parsed.max);
            for (var i = desempate.config_parsed.min; i <= desempate.config_parsed.max; i++) {
              weekGoalsValue.push(i);
            }
            // console.log(weekGoalsValue);
            return (
              <li key={desempate.id + i} className="desempate-element">
                <div className="desempate-container row">
                  <div className="col-xs-6 desempate-label">
                    <span>{desempate.label}</span>
                    <i
                      data-tip
                      data-for={`desempate-label-${desempate.id}`}
                      className={`ion-help-circled`}
                    />
                    <ReactTooltip
                      id={`desempate-label-${desempate.id}`}
                      place="top"
                      type="light"
                      effect="solid"
                    >
                      Recuerda, no tienes que escoger el número exacto,
                      <br /> si resulta empate el que se acerque más al resultado será el ganador.
                    </ReactTooltip>
                  </div>
                  <div className="col-xs-6 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}`}>
                      <FormControl
                        componentClass="select"
                        value={
                          tiesPicks.hasOwnProperty(desempate.id) && tiesPicks[desempate.id].value !== null
                          ?
                          tiesPicks[desempate.id].value
                          : ''
                        }
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                      >
                        <option value="" />
                        {weekGoalsValue.map((goal, i) => {
                          return (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          );
                        })}
                      </FormControl>
                      {/*<HelpBlock>{desempate.short_label}</HelpBlock>*/}
                      {/*<HelpBlock>Puedes ingresar un número del {desempate.config_parsed.min} al {desempate.config_parsed.max}</HelpBlock>*/}
                    </FormGroup>
                  </div>
                </div>
              </li>
            );
          } else if (
            /*********************
             * Type: Tournamnet Goals
             **********************/
            desempate.type === 'tournament_goals' ||
            desempate.type === 'tournament_winner_goals' ||
            desempate.type === 'tournament_looser_goals'
          ) {
            let weekGoalsValue = [];
            // console.log('%c Tournament Goals Value','background:#36A121');
            // console.log(desempate.config_parsed.min, desempate.config_parsed.max);
            for (let i = desempate.config_parsed.min; i <= desempate.config_parsed.max; i++) {
              weekGoalsValue.push(i);
            }
            // console.log(weekGoalsValue);
            return (
              <li key={desempate.id + i} className="desempate-element">
                <div className="desempate-container row">
                  <div className="col-xs-6 desempate-label">
                    <span>{desempate.label}</span>
                    <i
                      data-tip
                      data-for={`desempate-label-${desempate.id}`}
                      className={`ion-help-circled`}
                    />
                    <ReactTooltip
                      id={`desempate-label-${desempate.id}`}
                      place="top"
                      type="light"
                      effect="solid"
                    >
                      Recuerda, no tienes que escoger el número exacto,
                      <br /> si resulta empate el que se acerque más al resultado será el ganador.
                    </ReactTooltip>
                  </div>
                  <div className="col-xs-6 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}`}>
                      <FormControl
                        value={
                          !tiesPicks[desempate.id].value && tiesPicks[desempate.id].value !== 0
                            ? ''
                            : tiesPicks[desempate.id].value
                        }
                        componentClass="select"
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                      >
                        <option value="" />
                        {weekGoalsValue.map((goal, i) => {
                          return (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          );
                        })}
                      </FormControl>
                      {/*<HelpBlock>{desempate.short_label}</HelpBlock>*/}
                      {/*<HelpBlock>Puedes ingresar un número del {desempate.config_parsed.min} al {desempate.config_parsed.max}</HelpBlock>*/}
                    </FormGroup>
                  </div>
                </div>
              </li>
            );
          } else if (desempate.type === 'team') {
            /*********************
             * Type: Team
             **********************/
            let listaEquiposHome = fixtures.map((fixt) => fixt.home_team);
            let listaEquiposAway = fixtures.map((fixt) => fixt.away_team);
            let listaEquiposFull = listaEquiposHome.concat(listaEquiposAway);
            // console.log(listaEquiposFull);
            listaEquiposFull = listaEquiposFull.sort(firstBy('name'));
            listaEquiposFull = listaEquiposFull.map((equipo) => {
              return (
                <option key={equipo.id} value={equipo.id}>
                  {' '}
                  {equipo.name}
                </option>
              );
            });

            return (
              <li key={desempate.id + i} className="desempate-element">
                <div className="desempate-container row">
                  <div className="col-xs-6 desempate-label">
                    <span>{desempate.label}</span>
                    <i
                      data-tip
                      data-for={`desempate-label-${desempate.id}`}
                      className={`ion-help-circled`}
                    />
                    <ReactTooltip
                      id={`desempate-label-${desempate.id}`}
                      place="top"
                      type="light"
                      effect="solid"
                    >
                      Recuerda, no tienes que escoger el número exacto,
                      <br /> si resulta empate el que más se acerque al resultado ganará
                    </ReactTooltip>
                  </div>
                  <div className="col-xs-6 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}`}>
                      <FormControl
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                        value={
                          !tiesPicks[desempate.id] ||
                          (!tiesPicks[desempate.id].value && tiesPicks[desempate.id].value !== 0)
                            ? ''
                            : tiesPicks[desempate.id].value
                        }
                        componentClass="select"
                      >
                        <option value="">Elige un equipo</option>
                        {listaEquiposFull}
                      </FormControl>
                      {/*<HelpBlock>{desempate.short_label}</HelpBlock>*/}
                    </FormGroup>
                  </div>
                </div>
              </li>
            );
          } else if (
            /*********************
             * Type: Number
             **********************/
            desempate.type === 'number' ||
            desempate.type === 'first_goal' ||
            desempate.type === 'last_goal'
          ) {
            let weekGoalsValue = [];
            let teamLabel = '';
            let fullLabel = desempate.label;
            if (
              (fullLabel[fullLabel.length - 2] === '%') &
              (fullLabel[fullLabel.length - 1] === 's')
            ) {
              fullLabel = 'Puntos hechos por %s';
            }
            // console.log('%c Week Goals Value','background:#36A121');
            // console.log(desempate.config_parsed.min, desempate.config_parsed.max);
            for (let i = desempate.config_parsed.min; i <= desempate.config_parsed.max; i++) {
              weekGoalsValue.push(i);
            }
            // console.log('%c NUMBER', 'background:#36A121;font-size:30px;');
            // console.log(desempate);

            if (desempate.config_parsed.team && desempate.config_parsed.team === 'home') {
              fullLabel = fullLabel
                .replace('%s', fixtures[fixtures.length - 1].home_team.abbreviation );
            }
            if (desempate.config_parsed.team && desempate.config_parsed.team === 'away') {
              fullLabel = fullLabel
                .replace('%s', fixtures[fixtures.length - 1].away_team.abbreviation );
            }
            return (
              <li key={desempate.id + i} className="desempate-element">
                <div className="desempate-container row">
                  <div className="col-xs-6 desempate-label">
                    <span>{fullLabel}</span>
                    <i
                      data-tip
                      data-for={`desempate-label-${desempate.id}`}
                      className={`ion-help-circled`}
                    />
                    <ReactTooltip
                      id={`desempate-label-${desempate.id}`}
                      place="top"
                      type="light"
                      effect="solid"
                    >
                      Recuerda, no tienes que escoger el número exacto,
                      <br /> si resulta empate el que más se acerque al resultado ganará
                    </ReactTooltip>
                  </div>
                  <div className="col-xs-6 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}`}>
                      <FormControl
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                        value={
                          !tiesPicks[desempate.id].value && tiesPicks[desempate.id].value !== 0
                            ? ''
                            : tiesPicks[desempate.id].value
                        }
                        componentClass="select"
                      >
                        <option value="" />
                        {poolInfo.pool.sport_id === 1 ? (
                          <option value="nogoal">No hay gol</option>
                        ) : null}
                        {weekGoalsValue.map((goal, i) => {
                          return (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          );
                        })}
                      </FormControl>
                    </FormGroup>
                  </div>
                </div>
              </li>
            );
          } else if (desempate.type === 'score') {
            /*********************
             * Type: Score
             **********************/
            // console.log('Marcador Exacto ')
            return (
              <li key={desempate.id + i} className="desempate-element">
                <div className="desempate-container row">
                  <div className="col-xs-6 desempate-label">
                    <span>{desempate.label}</span>
                    <i
                      data-tip
                      data-for={`desempate-label-${desempate.id}`}
                      className={`ion-help-circled`}
                    />
                    <ReactTooltip
                      id={`desempate-label-${desempate.id}`}
                      place="top"
                      type="light"
                      effect="solid"
                    >
                      Recuerda, no tienes que escoger el número exacto,
                      <br /> si resulta empate el que más se acerque al resultado ganará
                    </ReactTooltip>
                  </div>
                  <div className="col-xs-3 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}-home`}>
                      <FormControl
                        value={
                          !tiesPicks[desempate.id].score_home
                            ? '' && tiesPicks[desempate.id].score_home !== 0
                            : tiesPicks[desempate.id].score_home
                        }
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                        type="number"
                      />
                      {/*<HelpBlock>{desempate.short_label}</HelpBlock>*/}
                      {/*<HelpBlock>Local</HelpBlock>*/}
                    </FormGroup>
                  </div>
                  <div className="col-xs-3 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}-away`}>
                      <FormControl
                        value={
                          !tiesPicks[desempate.id].score_away
                            ? '' && tiesPicks[desempate.id].score_away !== 0
                            : tiesPicks[desempate.id].score_away
                        }
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                        type="number"
                      />
                      {/*<HelpBlock>{desempate.short_label}</HelpBlock>*/}
                      {/*<HelpBlock>Vistiante</HelpBlock>*/}
                    </FormGroup>
                    {/*<HelpBlock>Puedes ingresar un número del {desempate.config_parsed.min} al {desempate.config_parsed.max}</HelpBlock>*/}
                  </div>
                </div>
              </li>
            );
          } else if (desempate.type === 'team_points') {
            /*********************
             * Type: Team Points
             **********************/
            // console.log('%c Team points Value','background:#36A121');
            // console.log(desempate.config_parsed.min, desempate.config_parsed.max);
            let weekGoalsValue = [];
            let typeTeam = null;
            let currentFixture = fixtures.find((fixture) => {
              if (fixture.id === desempate.config_parsed.fixture_id) {
                return fixture;
              }
            });
            if (currentFixture !== undefined) {
              // console.log('CurrentFixture', currentFixture)
              // if(desempate.config_parsed.team_id)
              if (currentFixture.home_team_id === desempate.config_parsed.team_id) {
                typeTeam = 'home';
              } else {
                typeTeam = 'away';
              }
            }

            for (let i = desempate.config_parsed.min; i <= desempate.config_parsed.max; i++) {
              weekGoalsValue.push(i);
            }
            // console.log('desempate.id', tiesPic[desempate.id]);
            return (
              <li key={desempate.id + i} className="desempate-element">
                <div className="desempate-container row">
                  <div className="col-xs-6 desempate-label">
                    <span>{desempate.label}</span>
                    <i
                      data-tip
                      data-for={`desempate-label-${desempate.id}`}
                      className={`ion-help-circled`}
                    />
                    <ReactTooltip
                      id={`desempate-label-${desempate.id}`}
                      place="top"
                      type="light"
                      effect="solid"
                    >
                      Recuerda, no tienes que escoger el número exacto,
                      <br /> si resulta empate el que más se acerque al resultado ganará
                    </ReactTooltip>
                  </div>
                  <div className="col-xs-6 desempate-field">
                    <FormGroup controlId={`tiebreaker-${desempate.type}-${desempate.id}`}>
                      <FormControl
                        value={
                          tiesPicks.hasOwnProperty(desempate.id) && tiesPicks[desempate.id].value !== null
                          ?
                          tiesPicks[desempate.id].value
                          : null
                        }
                        componentClass="select"
                        data-teamtype={typeTeam}
                        disabled={poolInfo.pool.closed}
                        className={`tie-${desempate.type}-${desempate.id}`}
                        onChange={(e) => {
                          updateTieData(e, entryID, weekID, desempates);
                        }}
                      >
                        <option value="" />
                        {weekGoalsValue.map((goal, i) => {
                          return (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          );
                        })}
                      </FormControl>
                      {/*<HelpBlock>{desempate.short_label}</HelpBlock>*/}
                      {/*<HelpBlock>Puedes ingresar un número del {desempate.config_parsed.min} al {desempate.config_parsed.max}</HelpBlock>*/}
                    </FormGroup>
                  </div>
                </div>
              </li>
            );
          }
        }
      });
    }
  } else {
    desempates = <div>...</div>;
  }
  conditonalComponent = (
    <div id={`desempates-data${entryID !== null ? '-' + entryID : ''}`}>
      <div className="title-quiniela sub">
        <span>DESEMPATES</span>
      </div>
      <ul className="lista-desempates">{desempates}</ul>
    </div>
  );
  return conditonalComponent;
}

export function PropsPartidos({
  index,
  entriesInfo,
  selectedEntry,
  numeroEntries,
  fixtures,
  fixtureProps,
  isLoaded,
  updatePicks,
  getWinningProp,
}) {
  // console.log(
  //   '%c %s %o',
  //   'background:#36A121; font-size:20px;',
  //   'PropsPartidos',
  //   this
  // );
  let entryID = null;
  if (entriesInfo !== null) {
    if (numeroEntries !== undefined) {
      entryID = selectedEntry;
    } else {
      entryID = selectedEntry;
    }
  }
  // if(this.state.selectedEntryData === undefined || this.state.selectedEntryData === null){
  //   entryID = this.state.selectedEntryData.id;
  // }
  // else {
  if (entriesInfo !== undefined || entriesInfo !== null) {
    if (entriesInfo.entry !== undefined || entriesInfo.entry !== null) {
      entryID = entriesInfo.entry.id;
    } else {
      entryID = entriesInfo.id;
    }
  } else {
    entryID = null;
  }
  // }
  let listFixtures = fixtures;
  let listProps = [];
  let listPropsTemp = [];
  let propsAvailable = false;
  let conditional;
  let multipleSingleFixture = null;
  let partidoAc;
  // console.log('Fixtures', listProps, listFixtures );
  if (listFixtures !== undefined) {
    listPropsTemp = fixtureProps;
    // console.log('PropsPartid<os length',listProps);
    // hay  minimo un prop disponible
    if (listPropsTemp.length > 0) {
      // Extraer el prop del partido
      listPropsTemp.forEach((prop, i) => {
        /**********************************
             //Multiple Props en un partido
             **********************************/
        propsAvailable = true;
        multipleSingleFixture = true;
        // console.log('Mas de un Fixture disponible', listProps, 'PropsAvailable',propsAvailable)
      });
    } else {
      propsAvailable = false;
      // console.log('0 fixtures disponibles', listProps, 'PropsAvailable',propsAvailable)
    }
  } else {
    // Props estan undefined
    // console.6log('propsPartidos Undefined')
    propsAvailable = false;
  }
  if (isLoaded) {
    // let listProps = listFixtures.map( (fixture, i) => fixture.props );
    // console.log('READY and LOADED',listProps);
    // console.log(listProps)
    listProps = listPropsTemp.map((prop, i) => {
      // if(multipleSingleFixture){
      // console.log('%c %s %o','font-size:20px;background:#009BF5;','Multiple listaProps', listProps,prop,this.props);

      // Render de quinielas multiple props in single fixture
      let currentFixture = fixtures.find((fixt) => fixt.id === prop.fixture_id);

      let closingDate = moment(currentFixture.deadline);

      let isDisabled = !prop.enabled || (utils.isLogged() && utils.isSuPick());

      // console.log(!prop.enabled, this);
      // console.log(isDisabled);
      partidoAc = prop;
      let nowDate = moment();
      let itPassedSec = closingDate.diff(nowDate, 'miliseconds');
      // console.log(closingDate, new Date());
      // console.log(itPassedSec)
      let format24h = false;
      let itStarted = false;
      let isLive = false;

      // this.props.updateStartedFixture(currentFixture.id);

      if (currentFixture.time_left > 0) {
        if (currentFixture.time_left > 0x7fffffff) {
          currentFixture.time_left = 0x7fffffff;
        }
        if (currentFixture.enable) {
          setTimeout(() => {
            // console.log(currentFixture.enabled, isDisabled);
            // console.log(itPassedSec);
            prop.enabled = false;
            isDisabled = true;
            console.log('Cerro!');
          }, itPassedSec);
        }
      }

      if (itPassedSec < 86400) {
        format24h = true;
      }

      if (itPassedSec < 0) {
        itStarted = true;
        isDisabled = true;
        if (!currentFixture.finished) {
          isLive = true;
        }
      }
      return (
        <ul key={i} className={`props-row ${prop.id}`} data-id={prop.id}>
          {itStarted ? (
            isLive ? (
              <span className="live-pool flex-container">
                <div className="flex-col col-20 col-no-padding live-label">Live!</div>
                <div className=" flex-col col-no-padding live-data ">
                  <div className="flex-container justify-end">
                    <span>
                      {currentFixture.stats && currentFixture.stats.hasOwnProperty('quarter')
                        ? currentFixture.stats.quarter !== null &&
                          currentFixture.stats.quarter !== ''
                          ? currentFixture.stats.quarter
                          : ''
                        : ''}
                    </span>
                    <span>
                      {currentFixture.stats && currentFixture.stats.hasOwnProperty('timer')
                        ? currentFixture.stats.timer !== null && currentFixture.stats.timer !== ''
                          ? currentFixture.stats.timer + '"'
                          : ''
                        : ''}
                      {currentFixture.stats && currentFixture.stats.hasOwnProperty('timer') ? (
                        currentFixture.stats.timer !== null && currentFixture.stats.timer !== '' ? (
                          <i className="ion-ios-timer" />
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </div>
              </span>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          <div className="titleProps row">{`${prop.name}  - [${currentFixture.home_team.abbreviation} VS  ${currentFixture.away_team.abbreviation}]`}</div>
          <div className="props-data">
            <label
              id={`propPartido-${prop.id}-under`}
              onClick={() => {
                updatePicks('propPartido', 'under', prop.id);
              }}
              className={`prop-category ${
                prop.fixture.suspended
                  ? null
                  : getWinningProp(prop.id, 'under') !== null
                  ? getWinningProp(prop.id, 'under') === true
                    ? 'gano selected '
                    : 'perdio selected'
                  : 'null'
              }`}
            >
              <li>
                <div className="radio-container">
                  <input
                    disabled={isDisabled}
                    id={`propPartido-${prop.id}-under`}
                    name={`propPartido-${prop.id}${entryID ? '-' + entryID : ''}`}
                    data-id={prop.id}
                    type="radio"
                    value={'under'}
                  />
                  <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
                </div>
                <span>{prop.boolean ? 'Si' : 'Menor'}</span>
              </li>
            </label>
            <li>
              {prop.boolean ? null : (
                <span className="prop-value">{prop.value === null ? 'PD' : prop.value}</span>
              )}
            </li>
            <label
              id={`propPartido-${prop.id}-over`}
              onClick={() => {
                updatePicks('propPartido', 'over', prop.id);
              }}
              className={`prop-category ${
                prop.fixture.suspended
                  ? null
                  : getWinningProp(prop.id, 'over') !== null
                  ? getWinningProp(prop.id, 'over') === true
                    ? 'gano'
                    : 'perdio'
                  : ''
              }`}
            >
              <li>
                <span>{prop.boolean ? 'No' : 'Mayor'}</span>
                {/*<small>{prop.result}{prop.winning_pick}</small>*/}
                <div className="radio-container">
                  <input
                    id={`propPartido-${prop.id}-over`}
                    disabled={isDisabled}
                    name={`propPartido-${prop.id}${entryID ? '-' + entryID : ''}`}
                    data-id={index}
                    type="radio"
                    value={'over'}
                  />
                  <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
                </div>
              </li>
            </label>
          </div>
        </ul>
      );
    });
    // console.log(listProps);
  }
  propsAvailable
    ? (conditional = (
        <div id={`props-data-${entryID}`}>
          {listProps.length === 0 ? null : (
            <div className="title-quiniela sub">
              <span>PROPS</span>
            </div>
          )}
          <div className="props-list">
            <Form>
              <FormGroup>
                <ul id={`props-data-${entryID}`} className="lista-Props">
                  {listProps}
                </ul>
              </FormGroup>
            </Form>
          </div>
        </div>
      ))
    : (conditional = null);
  return conditional;
}

export function MainHeaderPicksGrupo({
  bracketTeams,
  bracketInfo,
  bracketStages,
  entriesInfo,
  type,
  activeStage,
  format,
  poolInfo,
  fixturesInfo,
  fixtureProps,
  tiebreakInfo,
  showPdfConfirmation,
}) {
  // console.log('%c Main HPG','background:rgb(0, 159, 2); font-size:24px; color:#fff;');
  let variableTxt = null;
  let homeLogo = null;
  let headerList = [];
  let visitanteList = [];
  let localList = [];
  let lineaList = [];
  let resultadoList = [];
  let pickGanadorList = [];
  let titleList = null;
  let fixtureList = [];
  let propsList = [];
  let propsListTemp = [];
  let tiesList = [];

  let buttonDownload = (
    <div id="downloadPicks" className="flex-container justify align">
      <Button
        data-tip
        data-for="picksDownload"
        onClick={() => {
          showPdfConfirmation();
        }}
        className="btn-download"
      >
        <img src={iconPDF} className="pdf-icon" />
      </Button>
    </div>
  );

  switch (type) {
    case 'bracket':
      const bracketMatches = _.values(bracketStages);
      const myBracketPicks = !entriesInfo ? 0 : _.values(entriesInfo.bracket_picks);
      // console.log('BracketStages', bracketMatches[this.props.activeStage-1] );
      // console.log('MyBracketPicks', myBracketPicks );
      let myPicksFinalStage = null;
      let matchesList = null;
      let myPicksList = null;
      let finalMatches = null;

      /**
       * Finals
       */
      if (activeStage >= 2) {
        // console.log('Fase Final');
        matchesList = [
          ...bracketMatches[1],
          ...bracketMatches[2],
          ...bracketMatches[3],
          ...bracketMatches[4],
        ];
        matchesList = matchesList.sort(firstBy('sort_order'));
        finalMatches = matchesList;
        myPicksFinalStage = matchesList;
        // console.log('matchesList', matchesList);
        matchesList = matchesList.map((stage) => {
          return (
            <div key={stage.number} className="upper-table-item fixture">
              {stage.number}
            </div>
          );
        });
      } else {
        /**
         * Group Matches
         */
        matchesList = bracketMatches[activeStage - 1].sort(firstBy('sort_order'));

        matchesList = matchesList.map((stage) => {
          return (
            <div key={stage.number} className="upper-table-item fixture">
              {stage.number}
            </div>
          );
        });
      }

      titleList = ['', 'Ganador', 'Mis Pick'];
      if (!entriesInfo) {
        titleList = ['', 'Ganador'];
      }
      titleList = titleList.map((title, i) => {
        return (
          <div key={i} className="upper-table-category ">
            {title}
          </div>
        );
      });

      let resultadosList = [];
      // console.log('MatchesList again',matchesList);
      matchesList.forEach((match, matchIdx) => {
        if (bracketInfo.status === 'upcoming') {
          resultadosList[matchIdx] = (
            <div key={matchIdx} className="upper-table-item fixture">
              PD
            </div>
          );
        } else {
          // console.log(bracketMatches[this.props.activeStage - 1], matchIdx);
          let teamWinner = null;
          if (activeStage >= 2) {
            teamWinner = finalMatches[matchIdx].winning_pick;
          } else {
            teamWinner = bracketMatches[activeStage - 1][matchIdx].winning_pick;
          }
          // let teamWinner = 'PD';
          teamWinner = bracketTeams.find((team) => team.id === teamWinner);
          if (!teamWinner) {
            teamWinner = 'PD';
          }
          // console.log('TW',teamWinner);
          resultadosList[matchIdx] = (
            <div key={matchIdx} className="upper-table-item fixture">
              {teamWinner.hasOwnProperty('logo') ? (
                <i className={`${teamWinner.logo} quina `} />
              ) : (
                teamWinner
              )}
            </div>
          );
        }
      });

      if (activeStage >= 3) {
        // console.log('myPicksFinalStage', myPicksFinalStage);

        myPicksList = myPicksFinalStage.map((match, matchIdx) => {
          let isPick = undefined;

          if (!myBracketPicks) {
          } else {
            isPick = myBracketPicks.find((myBracketPick, myBracketIdx) => {
              // console.log(myBracketPick.reference , match.number)
              if (match.number === myBracketPick.reference) {
                return myBracketPick;
              }
            });
          }
          // console.log(isPick);
          if (!isPick) {
            // console.log('isPick undefined');
            return (
              <div key={matchIdx} className="upper-table-item fixture">
                <i className="ion-close-round" />
              </div>
            );
          } else {
            const currentTeam = bracketTeams.find((team) => team.id === isPick.pick);
            let isCorrect = !isPick.correct && isPick.correct !== null ? 'perdio' : '';
            // console.log('CurrentPick',isCorrect );
            // console.log('CurrentTeam', currentTeam );
            if (!currentTeam) {
              // console.log('isPick undefined');
              return (
                <div key={matchIdx} className="upper-table-item fixture">
                  <i className="ion-close-round" />
                </div>
              );
            }

            return (
              <div key={matchIdx} className="upper-table-item fixture">
                <span className={`shirt-picks team ${isCorrect} bracket`}>
                  <span
                    className={`${currentTeam.sport_id === 2 ? 'helmet' : ''}  quina ${
                      currentTeam.logo
                    } home `}
                  />
                </span>
              </div>
            );
          }
        });
      } else {
        myPicksList = bracketMatches[activeStage - 1].map((match, matchIdx) => {
          if (!myBracketPicks) {
            return;
          }
          let isPick = myBracketPicks.find((myBracketPick, myBracketIdx) => {
            // console.log(myBracketPick.reference , match.number)
            if (match.number === myBracketPick.reference) {
              return myBracketPick;
            }
          });
          // console.log(isPick);

          if (isPick === undefined) {
            // console.log('isPick undefined');
            return (
              <div key={matchIdx} className="upper-table-item fixture">
                <i className="ion-close-round" />
              </div>
            );
          } else {
            let isCorrect = !isPick.correct && isPick.correct !== null ? 'perdio' : '';

            const currentTeam = bracketTeams.find((team) => team.id === isPick.pick);
            // console.log('CurrentPick',isCorrect );
            // console.log('CurrentTeam', currentTeam );
            if (!currentTeam) {
              return (
                <div key={matchIdx} className="upper-table-item fixture">
                  <span className={`shirt-picks team`}>
                    <span>
                      <i className="ion-close-round" />
                    </span>
                  </span>
                </div>
              );
            }
            return (
              <div key={matchIdx} className="upper-table-item fixture">
                <span className={`shirt-picks team ${isCorrect} bracket`}>
                  <span
                    className={`${currentTeam.sport_id === 2 ? 'helmet' : ''}  quina ${
                      currentTeam.logo
                    } home `}
                  />
                </span>
              </div>
            );
          }
        });
      }

      // console.log( myPicksList );
      // props.entriesInfo.map( )

      return (
        <div className="row-list">
          <div className="title-container">{titleList}</div>
          <div className="picksData sync-horizontal">
            <div className="data-row">{matchesList}</div>
            <div className="data-row">{resultadosList}</div>
            {!entriesInfo ? '' : <div className="data-row">{myPicksList}</div>}
          </div>
          <div className="total-container">{buttonDownload}</div>
        </div>
      );

      break;

    case 'survivor':
      console.log('%c SurvivorMainHeaderPicksGrupo', 'color: #2e8ccf ; font-size: 2em ;');
      // let visitantesList = null;
      // let localList = null;
      // let lineaList = null;
      // let resultadosList = null;
      // let pickGanadorList = null;

      return (
        <div className="row-list">
          <div className="title-container">{titleList}</div>
          <div className="picksData sync-horizontal">
            <div className="data-row">{visitanteList}</div>
            <div className="data-row">{localList}</div>
            {format === 'line' ? <div className={'linea data-row'}>{lineaList}</div> : null}
            <div className="data-row">{resultadoList}</div>
            <div className="data-row">{pickGanadorList}</div>
          </div>
          <div className="total-container">{buttonDownload}</div>
        </div>
      );

      break;

    /**
     * Quinielas DataHeader
     */
    default:
      const tournamentInstance = poolInfo.pool.tournament_instances[0].id;
      // console.log(tournamentInstance)
      if (format === 'line') {
        titleList = ['Visitante', 'Línea', 'Local', 'Resultados', 'Pick Ganador'];
      } else {
        titleList = ['Visitante', 'Local', 'Resultados', 'Pick Ganador'];
      }

      titleList = titleList.map((title, i) => {
        return (
          <div key={`title-${i}`} className="upper-table-category font-chivo">
            {title}
          </div>
        );
      });

      visitanteList = fixturesInfo.map((fixture) => {
        return (
          <TeamHeader
            key={`TH-A-${fixture.away_team_id}`}
            fixture={fixture}
            which="away"
            pointFormat={poolInfo.pool.point_format}
          ></TeamHeader>
        );
      });

      localList = fixturesInfo.map((fixture) => {
        return (
          <TeamHeader
            key={`TH-H-${fixture.home_team_id}`}
            fixture={fixture}
            which="home"
            pointFormat={poolInfo.pool.point_format}
          ></TeamHeader>
        );
      });

      if (poolInfo.pool.point_format === 'line') {
        lineaList = fixturesInfo.map((fixture) => {
          return <LineHeader key={`LH-${fixture.id}`} fixture={fixture} />;
        });
      }

      resultadoList = fixturesInfo.map((fixture) => {
        return <ResultHeader key={`RH-${fixture.id}`} fixture={fixture} />;
      });

      pickGanadorList = fixturesInfo.map((fixture) => {
        return (
          <FixtureWinnerHeader
            key={`WH-${fixture.id}`}
            fixture={fixture}
            pointFormat={poolInfo.pool.point_format}
            allowTies={poolInfo.pool.sport.allow_ties}
          />
        );
      });

      //agregar props..
      if (poolInfo.pool.format === 'mixed') {
        let propList = fixtureProps.map((prop, idx) => {
          return (
            <div key={`prop-no-${prop.id}-${idx}`} className="upper-table-item ">
              <span>P#{idx + 1}</span>
            </div>
          );
        });

        let fillerList = fixtureProps.map((prop, idx) => {
          return <div key={`prop-f-${prop.id}-${idx}`} className="upper-table-item"></div>;
        });

        let propResults = fixtureProps.map((prop) => {
          return <PropsResultHeader key={`PRH-${prop.id}`} prop={prop} />;
        });

        let propWinner = fixtureProps.map((prop) => {
          return <PropWinnerHeader key={`PWH-${prop.id}`} prop={prop} />;
        });

        visitanteList = [...visitanteList, ...propList];
        localList = [...localList, ...fillerList];
        resultadoList = [...resultadoList, ...propResults];
        pickGanadorList = [...pickGanadorList, ...propWinner];

        if (poolInfo.pool.point_format === 'line') {
          let propLineList = fixtureProps.map((prop, idx) => {
            return (
              <div key={`prop-line-${prop.id}-${idx}`} className="upper-table-item">
                <span>{prop.value}</span>
              </div>
            );
          });
          lineaList = [...lineaList, ...propLineList];
        }
      }

      if (poolInfo.pool.use_tibreakers) {
        let tieHeaders = tiebreakInfo.map((tiebreaker, idx) => {
          let short = tiebreaker.short_label.substr(0, 8);
          return (
            <div key={`tie-name-${tiebreaker.id}-${idx}`} className="upper-table-item">
              <span>{short}</span>
            </div>
          );
        });

        let fillerList = tiebreakInfo.map((tiebreaker, idx) => {
          return (
            <div key={`tie-filler-${tiebreaker.id}-${idx}`} className="upper-table-item"></div>
          );
        });

        let tiebreakersResults = tiebreakInfo.map((tiebreaker) => {
          return <TiebreakerResultHeader key={`TRH-${tiebreaker.id}`} tiebreaker={tiebreaker} />;
        });

        visitanteList = [...visitanteList, ...tieHeaders];
        localList = [...localList, ...fillerList];
        resultadoList = [...resultadoList, ...tiebreakersResults];
        pickGanadorList = [...pickGanadorList, ...tiebreakersResults];

        if (poolInfo.pool.point_format === 'line') {
          lineaList = [...lineaList, ...fillerList];
        }
      }

      return (
        <div className="row-list">
          <div className="title-container">{titleList}</div>
          <div className="picksData sync-horizontal">
            <div className="data-row">{visitanteList}</div>
            {format === 'line' ? <div className={'linea data-row'}>{lineaList}</div> : null}
            <div className="data-row">{localList}</div>
            <div className="data-row">{resultadoList}</div>
            <div className="data-row">{pickGanadorList}</div>
          </div>
          <div
            className="total-container"
            style={{ width: poolInfo.pool.type === 'full' ? '14%' : '7%' }}
          >
            {buttonDownload}
          </div>
        </div>
      );
  }
}

export function EstatusQuiniela({
  entriesInfo,
  selectedEntry,
  poolInfo,
  selectedEntryData,
  tiesPicks,
}) {
  // console.log('%c %s %o','background: #009BF5;font-size:20px;','Estatus Quiniela',poolInfo);
  let conditionalComponent = null;
  return conditionalComponent;

  let totalProps = 0;
  let propsUser = 0;
  let totalPicks = 0;
  let tiebreakerUser = 0;
  let picksReady = false;
  let propsReady = false;
  let tiesReady = false;
  let statusReady = false;

  if (!poolInfo) {
    return null;
  }

  if (poolInfo.pool.format !== 'mixed') {
    propsReady = true;
  } else {
    totalProps = poolInfo.props_count;
  }
  totalPicks = poolInfo.fixtures_count + totalProps;

  // console.log(this,weekID);
  // console.log('EstatusQuiniela',this.props);

  /**
   *  User has entry
   * **/
  if (selectedEntryData !== null) {
    // console.log('%c %s %o', 'background:#36A121', 'Estatusquiniela Registrado');
    // console.log( 'Estatus Quiniela', selectedEntryData );
    // console.log('TiesUser', tiesPicks);
    /**
     * Ties
     * **/
    if (tiesPicks !== undefined && tiesPicks !== null) {
      tiebreakerUser = _.find(selectedEntryData.user_tiebreakers, (tie) => {
        return tie.value !== null || tie.score_home !== null || tie.score_away !== null;
      });
      // console.log('Find _> Result', tiebreakerUser);
      if (!tiebreakerUser) {
        tiesReady = false;
      } else {
        tiesReady = true;
      }
    } else {
      tiesReady = true;
    }

    /**
     * Props
     * **/
    propsUser = !selectedEntryData.pick_prop_count ? 0 : selectedEntryData.pick_prop_count;

    if (selectedEntryData.pick_prop_count === totalProps) {
      propsReady = true;
    } else {
      propsReady = false;
    }
    /**
     * Picks Fixutres
     * **/
    if (selectedEntryData.pick_count === poolInfo.fixtures_count) {
      picksReady = true;
    } else {
      picksReady = false;
    }
  } else {
    statusReady = false;
  }
  /**
   * Estatus Ready
   * **/
  if (picksReady && propsReady && tiesReady) {
    statusReady = true;
  }
  // console.log('Estatus Quiniela', selectedEntryData, this);
  // Si hay entradas
  // console.log(selectedEntryData);
  if (selectedEntryData !== undefined && selectedEntryData !== null) {
    // console.log('Si hay entrada');
    conditionalComponent = (
      <div className="flex- rowestatusWrapper">
        <div className="flex-row ">
          <div className=" flex-col col-no-padding estado-registro">
            <span>{'Estatus: '}</span>
            <span
              className="estatus-value"
              style={statusReady ? { color: '#87b13d', fontWeight: 'bold' } : { color: '#d3242b' }}
            >
              {' '}
              {statusReady ? ' Listo' : ' Pendiente'}{' '}
            </span>
          </div>
          <div className="flex-col col-no-padding picks-guardados-quiniela">
            <span>{'Picks Guardados: '}</span>
            <span
              className="picks-guardados-value"
              style={
                picksReady && propsReady
                  ? { color: '#87b13d', fontWeight: 'bold' }
                  : { color: '#d3242b' }
              }
            >
              {' '}
              {selectedEntryData.pick_count + propsUser} / {totalPicks}{' '}
            </span>
          </div>
          {selectedEntryData.pool.use_tibreakers ? (
            <div
              className="desempates-quiniela flex-col col-no-padding"
              id={`desempates-data-${entriesInfo !== null ? entriesInfo.entry.id : ''}`}
            >
              <span>Desempates:</span>
              <span
                className="desempates-value"
                style={tiesReady ? { color: '#87b13d', fontWeight: 'bold' } : { color: '#d3242b' }}
              >
                {' '}
                {tiesReady ? ' Listo' : ' Pendiente'}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    console.log('No hay entrada');
    conditionalComponent = (
      <div className="flex-col estatusWrapper">
        <div className="flex-row">
          <div className="flex-col col-no-padding estado-registro">
            <span>Estatus:</span>
            <span className="estatus-value" style={{ color: '#d3242b' }}>
              {' '}
              {'Pendiente'}{' '}
            </span>
          </div>
          <div className="flex-col col-no-padding picks-guardados-quiniela">
            <span>{'Picks Guardados:'}</span>
            <span className="picks-guardados-value" style={{ color: '#d3242b' }}>
              {' '}
              {'0'}/{totalPicks}{' '}
            </span>
          </div>
          <div className="flex-col col-no-padding desempates-quiniela ">
            <span>Desempates:</span>
            <span className="desempates-value" style={{ color: '#d3242b' }}>
              {' '}
              {'Pendiente'}{' '}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return conditionalComponent;
}

export function DisplayDate({ mobile, poolInfo, fecha, id, changeStatusPool }) {
  moment.locale('es');
  let styles;
  let tag;
  let fechaUn = moment(fecha);
  let fecha_actual = moment();
  //console.log(fecha_actual);
  fecha = moment(fechaUn).format(' ddd D MMM, H:mm');
  if (mobile) {
    fecha = moment(fechaUn).format(' D/MM/YY H:mm');
  }
  let faltante = fechaUn.diff(fecha_actual, 'seconds');
  // let faltante = faltante_.split(' ');
  // console.log('Tiempo',faltante, props)
  if (poolInfo.closed) {
    styles = {
      color: '#d3242b',
    };
    tag = (
      <div className="flex-container justify align column text-center" data-id={id} style={styles}>
        {'CERRADA'}
      </div>
    );
  } else if (poolInfo.status === 'live') {
    styles = {
      color: '#31A7F5',
    };
    tag = (
      <div
        className="flex-container justify align column text-center"
        data-id={id}
        style={styles}
      >{`En vivo`}</div>
    );
  } else {
    if (faltante < 86400) {
      styles = {
        color: '#F9CC30',
      };
      tag = (
        <div className="flex-row" data-id={id}>
          <ClockHandler
            id={poolInfo.id}
            type="row"
            changeStatusPool={changeStatusPool !== undefined ? changeStatusPool : null}
            poolId={poolInfo.id}
            endDate={poolInfo.deadline}
          />
        </div>
      );
    } else if (faltante < 0) {
      styles = {
        color: '#F9CC30',
      };
      tag = (
        <div
          className="flex-container justify align column text-center"
          data-id={id}
          style={styles}
        >
          'EN VIVO
        </div>
      );
    } else {
      tag = (
        <div className="flex-container justify align" data-id={id} style={styles}>{`${fecha}`}</div>
      );
    }
  }
  return tag;
}

export function DisplayTypes({ format, id }) {
  return (
    <div data-id={id} className="format-container">
      {format.point_format === 'line' ? (
        <div data-id={id} className="pool-format">
          <img data-id={id} className="helpers-icons" src={line} data-tip data-for="linea" />
          <ReactTooltip place="top" type="light" id="linea" effect="solid">
            Esta quiniela contiene línea
          </ReactTooltip>
        </div>
      ) : null}
      {format.point_format === 'natural' ? (
        <div data-id={id} className="pool-format">
          <img data-id={id} className="helpers-icons" src={natural} data-tip data-for="natural" />
          <ReactTooltip place="top" type="light" id="natural" effect="solid">
            Esta quiniela es natural
          </ReactTooltip>
        </div>
      ) : null}
      {format.guaranteed_prize !== null ? (
        <div data-id={id} className="pool-format">
          <img
            data-id={id}
            className="helpers-icons"
            src={garantizada}
            data-tip
            data-for="garant"
          />
          <ReactTooltip place="top" type="light" id="garant" effect="solid">
            Esta quiniela tiene premio garantizado
          </ReactTooltip>
        </div>
      ) : null}
      {format.brackets_entry_count !== undefined ? (
        <div data-id={id} className="pool-format">
          <img data-id={id} className="helpers-icons" src={bracket} data-tip data-for="bracket" />
          <ReactTooltip place="top" type="light" id="bracket" effect="solid">
            Bracket
          </ReactTooltip>
        </div>
      ) : null}
    </div>
  );
}

export function DisplayIconsType({ type, id, isPick5, variation, big, size }) {
  let src = iconQuiniela;
  switch (type) {
    case 'pool':
    case 'group_pool':
      switch (variation) {
        case 'pick5':
          src = iconPick5;
          break;
        case 'private':
          src = iconPrivate;
          break;
        case 'open':
          src = iconQuiniela;
          break;
        case 'limited':
          src = iconCl;
          break;
        case 'h2h':
          src = icon1vs1;
          break;
        case 'maxn':
          src = iconMax;
          break;
        case 'parlay':
          src = iconParlay;
          break;
        default:
          src = iconQuiniela;
          break;
      }
      return (
        <div data-id={id}>
          <img
            data-id={id}
            src={src}
            className="pool-icons pool game-icon"
            style={(big === true && { width: '70px', height: '70px' }) || (size) && {width: size, height: size}}
          />
        </div>
      );
    case 'survivor':
      return (
        <div data-id={id}>
          <img
            data-id={id}
            src={iconSurvivor}
            className="pool-icons pool game-icon"
            style={(big === true && { width: '70px', height: '70px' }) || (size) && {width: size, height: size}}
          />
        </div>
      );
    case 'bracket':
      return (
        <div data-id={id}>
          <img
            data-id={id}
            src={bracketIcon}
            className="pool-icons pool game-icon"
            style={(big === true && { width: '70px', height: '70px' }) || (size) && {width: size, height: size}}
          />
        </div>
      );
    default:
      return (
        <div data-id={id}>
          <img
            data-id={id}
            src={src}
            className="pool-icons pool game-icon"
            style={(big === true && { width: '70px', height: '70px' }) || (size) && {width: size, height: size}}
          />
        </div>
      );
  }
}

export function DisplaySport({ sports, type, dataId, size }) {
  // console.log('DisplaySport', sports, type);

  // console.log( !sport )
  if (!sports) {
    return null;
  }
  const sport = sports;
  let sportName = sport.name;
  let tag = null;

  if (type === 'pool') {
    switch (sportName) {
      case 'Baseball':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Baseball} className="pool-icons pool sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Basketball':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Basketball} className="pool-icons pool sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Fútbol':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Fútbol} className="pool-icons pool sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Golf':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Golf} className="pool-icons pool sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Tenis':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Tennis} className="pool-icons pool sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Fútbol Americano':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Americano} className="pool-icons pool sport-icon" style={{height: size}} />
          </div>
        );
        return tag;
        break;
      case 'Volante':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Volante} className="pool-icons pool sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Hockey':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Hockey} className="pool-icons pool sport-icon" style={{height: size}} />
          </div>
        );
        return tag;
        break;
      case 'F1':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={F1} className="pool-icons pool sport-icon" style={{height: size}} />
          </div>
        );
        return tag;

      default:
        tag = null;
        return tag;
        break;
    }
  } else if (type === 'quiniela') {
    // console.log(this.props);
    const sport = sports.btn_class === undefined ? sports.split('-') : sports.btn_class.split('-');
    let sportName = sport[2];
    let tag = null;
    switch (sportName) {
      case 'baseball':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Baseball} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'basketball':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Basketball} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'futbol':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Fútbol} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'golf':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Golf} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'Tenis':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Tennis} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'americano':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Americano} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'volante':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} type src={Volante} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'hockey':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Hockey} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'F1':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={F1} className="pool-icons pool sport-icon" style={{height: size}} />
          </div>
        );
        return tag;
      default:
        tag = null;
        return tag;
        break;
    }
  } else if (type === 'group_pool') {
    // console.log('GroupPool')
    switch (sportName) {
      case 'golf':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Golf} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
      case 'F1':
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={F1} className="pool-icons pool sport-icon" style={{height: size}} />
          </div>
        );
        return tag;
        break;
      default:
        tag = (
          <div data-id={dataId}>
            <img data-id={dataId} src={Golf} className="pool-icons quiniela sport-icon" style={{height: size}}/>
          </div>
        );
        return tag;
        break;
    }
  }
  //return tag;
}

export function DisplayFlag({ type, flag }) {
  if (type === 'quiniela') {
    //let flag;
    let tag;
    if (flag === undefined) {
      flag = null;
      tag = null;
    } else if (flag.tournament_instances[0].tournament === undefined) {
      flag = null;
      tag = null;
    } else {
      flag = flag.tournament_instances.map((tournament) => {
        return (
          <div
            key={tournament.id}
            data-id={flag.id}
            className={`
                  flag
                  ${tournament.tournament.flag_class} miquiniela`}
          />
        );
      });
    }
    return (
      <div data-id={flag ? flag.id : null} className="flag-icon">
        {flag}
      </div>
    );
  } else if (type === 'pool') {
    if (!flag || !flag.tournament_instances) {
      return null;
    }
    let tag = flag.tournament_instances.map((tournament) => {
      if (tournament.tournament === undefined || !tournament.tournament.flag_class) {
        return null;
      }
      return (
        <i
          key={tournament.id}
          data-id={flag.id}
          className={`flex-container flag ${tournament.tournament.flag_class} miquiniela flag-icon`}
        />
      );
    });

    return (
      <span
        style={{ display: 'flex', flexDicrection: 'row' }}
        data-id={flag.id}
        className="flag-icon"
      >
        {tag}
      </span>
    );
  } else if (type === 'entries') {
    let flag;
    let tag;
    if (flag === undefined) {
      flag = null;
      tag = null;
    } else {
      tag = flag.tournament_instances.map((tournament) => {
        return (
          <div
            key={tournament.id}
            data-id={flag.id}
            className={`${tournament.tournament.flag_class} miquiniela flag-icon`}
          />
        );
      });
    }
    return <div data-id={flag.id}>{tag}</div>;
  } else if (type === 'misQuinas') {
    return <div className={`${flag} entries`} />;
  } else if (type === 'miPanel') {
    let tag = flag.tournament_instances.map((tournament) => {
      // console.log(tournament.tournament.flag_class)
      if (!tournament.tournament.flag_class) {
        return null;
      }
      return (
        <i
          key={tournament.id}
          data-id={flag.id}
          className={`flex-container flag ${tournament.tournament.flag_class} miquiniela flag-icon`}
        />
      );
    });
    return (
      <div className={`${tag.length > 1 ? 'flex-row' : ''}`} data-id={flag.id}>
        {tag}
      </div>
    );
  } else {
    return null;
  }
  return null;
  //  console.log('DF',props.flag.tournament_instances[0].tournament.flag_class)
}

export function DisplayEntries({ entries, id }) {
  let currencyIcon = null;
  let currencyFormat = null;
  let currencyAbbrev = null;
  let currencyColor = {};
  switch (entries.entry_currency) {
    case 'real':
      currencyIcon = <img src={billete} className="pool-icons pool billete" />;
      currencyFormat = '$0,000';
      currencyAbbrev = 'MXN';
      currencyColor = {};
      break;
    case 'pickcoin':
      currencyIcon = <img src={pickcoins} className="pool-icons pool" />;
      currencyFormat = '0,000';
      currencyAbbrev = 'PC';
      break;
    case 'referral':
      currencyIcon = <img src={pickcoinsPlata} className="pool-icons pool" />;
      currencyFormat = '0';
      currencyAbbrev = 'TK';
      break;
    case 'freeroll':
      currencyIcon = <img src={freeRollIcon} className="pool-icons pool" />;
      currencyFormat = '0';
      currencyAbbrev = '';
      break;
  }
  //console.log('Entries:', props);
  let tag;

  if (entries.entry_currency === 'ticket') {
    return (
      <img
        src={ticketIcon}
        alt='ticket'
        className="pool-icons pool billet"
        style={{ height: 15 }}
      />
    )
  }

  entries.entry_fee === 0
    ? (tag = (
        <div data-id={id} className="entry">
          Gratuita
        </div>
      ))
    : (tag = (
        <div data-id={id} className="entry">
          {currencyIcon}
          <span
            data-id={id}
            className="entriestxt"
            style={entries.guaranteed_prize ? { color: '#F9CC30' } : null}
          >
            {numeral(entries.entry_fee / 100).format(currencyFormat)}
          </span>
        </div>
      ));
  return tag;
}

export function GetShirt({ teamAbbreviation, fixtures, pool }) {
  // console.log(props, this);
  let conditionalComponent;
  let isHome = null;
  let abbreviation = teamAbbreviation.pres;
  let teamData = fixtures.find((fixture) => {
    if (fixture.home_team.abbreviation === abbreviation) {
      isHome = true;
      return fixture.home_team;
    }
    if (fixture.away_team.abbreviation === abbreviation) {
      isHome = false;
      return fixture.away_team;
    }
  });
  if (teamData !== undefined) {
    // console.log('Found This', teamData, isHome);
    if (isHome) {
      if (teamData.home_team !== null) {
        conditionalComponent = (
          <span
            className={`quina team-16-${abbreviation.toLowerCase()}-${
              teamData.home_team_id
            } home picksG `}
          />
        );
      } else {
        conditionalComponent = (
          <span
            className={`quina team-16-${abbreviation.toLowerCase()}-${
              teamData.home_team_id
            } home picksG `}
          />
        );
      }
    } else {
      if (teamData.away_team !== null) {
        conditionalComponent = (
          <span
            className={`quina team-16-${abbreviation.toLowerCase()}-${
              teamData.away_team_id
            } home picksG `}
          />
        );
      } else {
        conditionalComponent = (
          <span
            className={`quina team-16-${abbreviation.toLowerCase()}-${
              teamData.away_team_id
            } home picksG `}
          />
        );
      }
    }
    return conditionalComponent;
  } else {
    // console.log('Team Not Found');
    //return <DisplayFlag type="picks" flag={pool} />;
    return <div>picks</div>;
  }
}

export function PanelConfirmation({
  submitting,
  id,
  showConfirmation,
  hideConfirmation,
  title,
  label,
  afterConfirmation,
  errorStatus,
  errorLabel,
  errorTitle,
}) {
  return (
    <Modal
      bsSize="sm"
      dialogClassName="confirmationPanelPopUp"
      show={showConfirmation}
      keyboard={false}
    >
      <div className="panel-abonar">
        <div
          id="panelTitle"
          className={`flex-row row-no-padding panel-abonar-titulo ${errorStatus ? 'failed' : ''}`}
        >
          <span>{errorStatus ? errorTitle : title}</span>
          <button className="cerrar-panel" onClick={hideConfirmation}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-alert-circled alert" />
              {errorStatus ? errorLabel : label}
            </div>
          </div>
        </div>
        <div className="flex-row row-center">
          <div className="flex-col col-90 col-offset-5">
            <div className="flex-row">
              <Button
                className="flex-col col-40 col-offset-5 col-center btn-rojo btn-submit text-center"
                onClick={() => {
                  hideConfirmation();
                }}
              >
                No
              </Button>
              <Button
                disabled={submitting}
                className="flex-col col-40 col-offset-5 col-center btn-pick btn-submit text-center"
                onClick={() => {
                  submitting = true;
                  afterConfirmation()
                    .then((response) => {
                      console.log('Response', response);
                      if (response) {
                        hideConfirmation();
                      }
                    })
                    .catch((error) => {
                      console.error(error, 'error in confirmation');
                    });
                }}
              >
                {submitting ? (
                  <div className="flex-container justify align column">
                    <i className="ion-load-a loading small"></i>
                  </div>
                ) : (
                  'Si'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function RowBadge({ pool, label, condition }) {
  let conditionalComponent = null;
  // console.log('RowBadge');
  // console.log( condition, pool[condition]);
  if (!pool[condition] || pool[condition].length <= 0) {
    return conditionalComponent;
  }

  conditionalComponent = (
    <div className="pool-format">
      <ReactTooltip id={`max-icon`} place="top" type="light" effect="solid">
        Esta quiniela tiene un max de registros.
      </ReactTooltip>
      <img src={maxIcon} data-tip="true" data-for="max-icon" className="helpers-icons" />
    </div>
  );

  return conditionalComponent;
}

// mis mini componentes para las tabla de picks de gpo
const TeamHeader = ({ fixture, which, pointFormat }) => {
  let team = which === 'away' ? fixture.away_team : fixture.home_team;
  let favorite = null;
  if (pointFormat === 'line' && fixture.favorite === 'home' && which === 'home') {
    favorite = 'fav';
  }
  if (pointFormat === 'line' && fixture.favorite === 'away' && which === 'away') {
    favorite = 'fav';
  }

  return (
    <div className="upper-table-item fixture">
      <span className={`shirt-picks team flex-container column justify ${favorite}`}>
        <i className={`${team.logo} team-icon`}></i>
        <span className="team-name">{team.abbreviation}</span>
      </span>
    </div>
  );
};

const LineHeader = ({ fixture }) => {
  let advantage = 'PD';
  if (fixture.favorite && fixture.advantage !== null) {
    advantage = fixture.advantage;
  }

  return (
    <div className="upper-table-item">
      <span>{advantage}</span>
    </div>
  );
};

const ResultHeader = ({ fixture }) => {
  return (
    <div className="upper-table-item fixture">
      <span>
        {fixture.score_away}-{fixture.score_home}
      </span>
    </div>
  );
};

const PropsResultHeader = ({ prop }) => {
  let resultTx = 'PD';
  if (prop.fixture.sort_status !== 1) {
    if (prop.boolean) {
      if (prop.result) {
        resultTx = 'SI';
      } else {
        resultTx = 'NO';
      }
    } else {
      resultTx = prop.result;
    }
  }

  return (
    <div className="upper-table-item fixture">
      <span>{resultTx}</span>
    </div>
  );
};

const FixtureWinnerHeader = ({ fixture, pointFormat, allowTies }) => {
  if (fixture.sort_status !== 1) {
    let winnerTxt = null;
    let team = null;
    if (
      (pointFormat === 'line' && fixture.winning_pick_line === 'home') ||
      (pointFormat === 'natural' && fixture.winning_pick === 'home')
    ) {
      winnerTxt = fixture.home_team.abbreviation;
      team = fixture.home_team;
    }

    if (
      (pointFormat === 'line' && fixture.winning_pick_line === 'away') ||
      (pointFormat === 'natural' && fixture.winning_pick === 'away')
    ) {
      winnerTxt = fixture.away_team.abbreviation;
      team = fixture.away_team;
    }
    if (fixture.suspended) {
      winnerTxt = '/';
    } else {
      if (
        (allowTies && pointFormat === 'natural' && fixture.winning_pick === 'tie') ||
        (pointFormat === 'line' && fixture.winning_pick_line === 'tie')
      ) {
        winnerTxt = 'E';
      }

      if (
        (!allowTies && pointFormat === 'natural' && fixture.winning_pick === 'tie') ||
        (pointFormat === 'line' && fixture.winning_pick_line === 'tie')
      ) {
        winnerTxt = '-';
      }
    }

    return (
      <div className="upper-table-item fixture">
        <div className="shirt-picks team">
          <div className="flex-container column justify">
            {team !== null ? (
              <>
                <i className={`${team.logo} team-icon`}></i>
                <span className="team-name">{winnerTxt}</span>
              </>
            ) : (
              winnerTxt
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="upper-table-item fixture">
        <div className="shirt-picks team">
          <div className="flex-container column justify align">
            <span>PD</span>
          </div>
        </div>
      </div>
    );
  }
};

const PropWinnerHeader = ({ prop }) => {
  let winnerTx = 'PD';

  if (prop.fixture.sort_status !== 1) {
    if (!prop.boolean && prop.winning_pick === 'under') winnerTx = <i className="ion-minus"></i>;

    if (!prop.boolean && prop.winning_pick === 'over') winnerTx = <i className="ion-plus"></i>;

    if (prop.boolean && prop.winning_pick === 'under') winnerTx = <i className="ion-checkmark"></i>;

    if (prop.boolean && prop.winning_pick === 'over') winnerTx = <i className="ion-close"></i>;

    if (prop.winning_pick === 'tie') winnerTx = '-';

    if (prop.fixture.suspended) winnerTx = '/';
  }

  return (
    <div className="upper-table-item fixture">
      <div className="shirt-picks team">
        <div className="flex-container column justify align">{winnerTx}</div>
      </div>
    </div>
  );
};

const TiebreakerResultHeader = ({ tiebreaker }) => {
  let resultTx = 'PD';
  if (!tiebreaker.enabled) {
    resultTx = tiebreaker.result === null ? 'PD' : tiebreaker.result;
  }
  return (
    <div className="upper-table-item fixture">
      <span>{resultTx}</span>
    </div>
  );
};

export default { ErrorMessage, ErrorMessages, ErrorMessageValidation };
